import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

const findError = (entity, prefix) => {
  const keys = Object.keys(entity);
  if (keys.length > 0) {
    const selector = prefix ? `${prefix}.${keys[0]}` : keys[0];
    const array = document.getElementsByName(`${selector}`);
    if (array.length > 0) return array[0];
    if (entity[keys[0]] instanceof Array) {
      const item = entity[keys[0]].find((el) => el !== undefined);
      const index = entity[keys[0]].indexOf(item);
      return findError(item, `${selector}[${index}]`);
    }
    return findError(entity[keys[0]], selector);
  }
};

export const ErrorListener = () => {
  const { errors, isSubmitting, isValidating } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const error = findError(errors, '');
      if (error) {
        error.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
        error.focus();
      }
    }
  }, [errors, isSubmitting, isValidating]);

  return null;
};
