import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Constants } from '../../../helpers';

const useStyles = makeStyles((theme) => ({
  bottom: {
    marginBottom: theme.spacing(1.5)
  }
}));

export const Checkbox = ({ checkBeforeText = false, size = 'lg', className = '', errors = '', children, ...props }) => {
  const styles = useStyles();
  return (
    <>
      <label
        className={`checkbox ${size === 'lg' ? 'checkbox-lg' : ''} ${props.disabled ? 'checkbox-disabled' : ''} ${
          styles.bottom
        } ${className}`}
      >
        <input type='checkbox' {...props} />
        {checkBeforeText && (
          <>
            <span className='mr-3' /> {children}
          </>
        )}
        {!checkBeforeText && (
          <>
            {children} <span className='ml-3' />
          </>
        )}
      </label>
      {errors && <div style={{ color: Constants.ERROR_COLOR }}>{errors}</div>}
    </>
  );
};
