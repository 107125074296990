import React, { forwardRef } from 'react';

export const FileButton = forwardRef(
  ({ children, className = '', disabled = false, accept = '.xls, .xlsx', ...rest }, ref) => {
    return (
      <label ref={ref} className="mb-0">
        <input type="file" id="hidden_file" disabled={disabled} accept={accept} {...rest} className="d-none" />
        <label
          htmlFor="hidden_file"
          className={`btn btn-secondary mb-0 ${disabled ? 'disabled cursor-default' : ''} ${className}`}
        >
          {children}
        </label>
      </label>
    );
  }
);
