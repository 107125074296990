import { FormattedMessage } from 'react-intl';
import { Modal } from 'react-bootstrap';
import { ModalProgressBar } from './ModalProgressBar';
import { ActionButton } from '../controls';

export const ModalActionDialog = ({
  loading,
  show,
  title = <FormattedMessage id="general.confirmation" />,
  body,
  footer = undefined,
  cancelLabel = <FormattedMessage id="general.cancel" />,
  actionLabel = <FormattedMessage id="general.confirm" />,
  cancelClick = () => {},
  actionClick = () => {},
  cancelDisabled = false,
  actionDisabled = false,
  cancelType = 'button',
  actionType = 'button',
  cancelStyle = 'btn-light ml-8',
  actionStyle = 'btn-primary ml-8',
  hideFooter = false
}) => {
  return (
    <Modal show={show} onHide={() => {}}>
      {/*begin::Loading*/}
      {loading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      {!hideFooter && (
        <Modal.Footer className="px-6 py-3">
          {!footer && (
            <div className="d-flex justify-content-end w-100">
              <ActionButton
                type={cancelType}
                disabled={loading || cancelDisabled}
                className={cancelStyle}
                onClick={cancelClick}
              >
                {cancelLabel}
              </ActionButton>
              <ActionButton
                type={actionType}
                disabled={actionDisabled}
                loading={loading}
                onClick={actionClick}
                className={actionStyle}
              >
                {actionLabel}
              </ActionButton>
            </div>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};
