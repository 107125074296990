import { createSlice } from '@reduxjs/toolkit';
import { Constants } from '../../../../../helpers';

export const initialCreateSchool = {
  schoolName: '',
  ncesSchoolId: '',
  city: '',
  address: '',
  username: '',
  password: '',
  firstname: '',
  email: '',
  phone: '',
  logoPath: '',
  userLanguageId: 2,
  groupId: 1,
  templateId: 8,

  changeEmail: true,
  changePassword: false,
  showLeaderBoard: true,
  showEbPyramid: false,
  transactionInfo: {
    // input
    nameoncheck: '',
    chequeNumber: '',
    amount: 600,
    drn: '', // deposit slip
    // date picker
    chequeDate: '',
    depositDate: '',
    // select
    subscription: 12,
    categoryId: '5', //school
    paymentId: 1,
    bankId: '',
    salespersonId: ''
    // transactionId,pfirstname,plastname,kfirstname,klastname on edit response
    // pfirstname same with school.firstname
  }
};

export const initialFilter = {
  filter: {
    ncesSchoolId: '',
    schoolName: '',
    city: '',
    address: '',
    username: '',
    firstname: ''
  },
  sortOrder: 'desc', // asc||desc
  sortField: 'id',
  pageNumber: 1,
  pageSize: 10
};

const initialSchoolsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  schoolForEdit: undefined,
  lastInfo: undefined,
  lastError: null,
  currentSchools: null,
  currentAction: null,
  newCreatedSchool: undefined,
  sourceEntities: null,
  startSort: undefined,
  dirty: undefined,
  processedItem: undefined,
  schoolsForExcel: undefined,
  studentsForExcel: undefined,
  listFilter: initialFilter
};

export const callTypes = {
  list: 'list',
  action: 'action'
};

export const schoolsSlice = createSlice({
  name: 'schools',
  initialState: initialSchoolsState,
  reducers: {
    reinitialize: (state, action) => ({ ...initialSchoolsState }),
    // store form status
    storeFormStatus: (state, action) => {
      state.dirty = action.payload.dirty;
    },
    // config `sort local cache table`
    setupSortTable: (state, action) => {
      state.startSort = action.payload.startSort;
    },
    clearInfoAndErrorState: (state, action) => {
      state.lastInfo = null;
      state.lastError = null;
      state.dirty = false;
      state.currentAction = null;
      state.currentSchools = null;
      state.processedItem = null;
    },
    catchError: (state, action) => {
      const { lastError, callType } = action.payload;
      state.lastError = lastError;
      state.currentSchools = null;
      state.currentAction = null;
      state.processedItem = null;
      if (callType === callTypes.list) {
        state.listLoading = false;
        if (lastError.errorKeys && lastError.errorKeys.find((e) => e === Constants.PERMISSION_DENIED)) {
          state.entities = null;
          state.sourceEntities = null;
          state.totalCount = 0;
        }
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.lastError = null;
      state.processedItem = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
        state.currentSchools = null;
        state.currentAction = null;
      }
    },
    // end call only use when no action match
    endCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    // getSchoolById
    schoolFetched: (state, action) => {
      state.actionsLoading = false;
      state.schoolForEdit = action.payload.schoolForEdit;
      state.lastError = null;
    },
    // Complete `sort table data`
    schoolsTableSorted: (state, action) => {
      const { entities, listFilter } = action.payload;
      state.entities = entities;
      state.listFilter = listFilter;
    },
    // findSchools
    schoolsFetched: (state, action) => {
      const { totalCount, entities, sourceEntities, listFilter } = action.payload;
      state.listLoading = false;
      state.lastError = null;
      state.entities = entities;
      state.sourceEntities = sourceEntities;
      state.totalCount = totalCount;
      state.listFilter = listFilter;
      if (!state.lastInfo) {
        state.dirty = false;
        state.currentAction = null;
        state.currentSchools = null;
        state.newCreatedSchool = null;
        state.processedItem = null;
        state.schoolsForExcel = null;
        state.studentsForExcel = null;
      } else {
        state.lastInfo = null;
      }
    },
    // createSchool
    schoolCreated: (state, action) => {
      const { newCreatedSchool, currentAction } = action.payload;
      state.actionsLoading = false;
      state.currentSchools = newCreatedSchool.schoolName;
      state.currentAction = currentAction;
      state.newCreatedSchool = newCreatedSchool;
      state.lastError = null;
      state.lastInfo = true;
    },
    clearAccountCreationWizard: (state, action) => {
      state.actionsLoading = false;
      state.lastError = null;
      state.newCreatedSchool = null;
    },
    // updateSchool
    schoolUpdated: (state, action) => {
      state.lastError = null;
      state.lastInfo = true;
      state.currentSchools = action.payload.schoolName;
      state.currentAction = action.payload.currentAction;
      state.actionsLoading = false;
    },
    // deleteSchool
    schoolDeleted: (state, action) => {
      const { id, currentAction } = action.payload;
      state.lastInfo = true;
      state.lastError = null;
      state.currentAction = currentAction;
      state.currentSchools = state.entities.find((el) => el.id === id)?.schoolName;
      state.actionsLoading = false;
      state.sourceEntities = state.sourceEntities.filter((el) => el.id !== id);
      state.entities = state.entities.filter((el) => el.id !== id);
    },
    // deleteSchools
    schoolsDeleted: (state, action) => {
      const { ids, currentAction } = action.payload;
      state.lastInfo = true;
      state.lastError = null;
      state.currentAction = currentAction;
      state.currentSchools = ids.map((id) => state.entities.find((el) => el.id === id)?.schoolName).join(', ');
      state.actionsLoading = false;
      state.sourceEntities = state.sourceEntities.filter((el) => !ids.includes(el.id));
      state.entities = state.entities.filter((el) => !ids.includes(el.id));
    },
    // download complete
    downloadCompleted: (state, action) => {
      state.lastError = null;
      state.actionsLoading = false;
      state.processedItem = null;
    },
    // excel send to email
    excelSent: (state, action) => {
      state.lastInfo = true;
      state.lastError = null;
      state.actionsLoading = false;
      state.processedItem = { type: 1, value: action.payload.email };
    },
    // end of year procedure
    procedureDone: (state, action) => {
      state.lastInfo = true;
      state.lastError = null;
      state.actionsLoading = false;
      state.processedItem = { type: 2, value: action.payload.endOfYear };
    },
    // update teacher & students state
    changedTeacherStudentState: (state, action) => {
      state.lastInfo = true;
      state.lastError = null;
      state.actionsLoading = false;
      state.processedItem = { type: 3, value: action.payload.state };
    },
    // upload excel schools
    schoolsExcelUploaded: (state, action) => {
      const { lastError, dirty, schoolsForExcel } = action.payload;
      state.lastInfo = true;
      state.lastError = lastError;
      state.actionsLoading = false;
      state.dirty = dirty;
      state.schoolsForExcel = schoolsForExcel;
    },
    // upload excel students: expiration data, set MathWorld, set EnglishWorld
    studentsExcelUploaded: (state, action) => {
      const { lastError, dirty, studentsForExcel } = action.payload;
      state.lastInfo = true;
      state.lastError = lastError;
      state.actionsLoading = false;
      state.dirty = dirty;
      state.studentsForExcel = studentsForExcel;
    }
  }
});
