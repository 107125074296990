/* eslint-disable */
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, Switch, Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import '../../../../assets/sass/pages/login/classic/login-1.scss';
import { toAbsoluteUrl, parseData } from '../../../../helpers';
import { ContentRoute } from '../../../../layout';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import { actionTypes } from '../redux/authRedux';

export function AuthPage() {
  const year = new Date().getFullYear();

  const search = window.location.search;
  if (search) {
    const params = parseData(search.slice(1));
    const u = params.get('u');
    const p = params.get('p');
    if (u && p) {
      const dispatch = useDispatch();
      dispatch({ type: actionTypes.LOGIN, payload: { username: u, password: p } });
    }
  }

  return (
    <>
      <div className='d-flex flex-column flex-root'>
        {/*begin::Login*/}
        <div
          className='login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white'
          id='kt_login'
        >
          {/*begin::Aside*/}
          <div
            className='login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10'
            style={{
              backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-4.jpg')})`
            }}
          >
            {/*begin: Aside Container*/}
            <div className='d-flex flex-row-fluid flex-column justify-content-between'>
              {/* start:: Aside header */}
              <Link to='/' className='flex-column-auto mt-5 pb-lg-0 pb-10'>
                <img alt='Logo' className='max-h-70px' src={toAbsoluteUrl('/media/logos/logo-itelij.png')} />
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className='flex-column-fluid d-flex flex-column justify-content-center'>
                <h3 className='font-size-h1 mb-5 text-white'>
                  <FormattedMessage id='general.welcome' />
                </h3>
                <p className='font-weight-lighter text-white opacity-80'>
                  <FormattedMessage id='general.planetii' />
                  &nbsp;&nbsp;
                  <FormattedMessage id='general.planetii.brief' />
                </p>
                <p className='font-weight-lighter text-white opacity-80'>
                  <FormattedMessage id='general.planetii' />
                  &nbsp;&nbsp;
                  <FormattedMessage id='general.planetii.desc' />
                  <FormattedMessage id='general.planetii.desc2' />
                </p>
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className='d-none flex-column-auto d-lg-flex justify-content-between mt-10'>
                <div className='opacity-70 font-weight-bold	text-white'>{year.toString()} &copy; PLANETii</div>
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className='d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden'>
            {/* begin::Content body */}
            <div className='d-flex flex-column-fluid flex-center mt-30 mt-lg-0'>
              <Switch>
                <ContentRoute path='/auth/login' component={Login} />
                <ContentRoute path='/auth/forgot-password' component={ForgotPassword} />
                <Redirect from='/auth' exact={true} to='/auth/login' />
                <Redirect to='/auth/login' />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className='d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5'>
              <div className='text-dark-50 font-weight-bold order-2 order-sm-1 my-2'>
                {year.toString()} &copy; PLANETii
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
