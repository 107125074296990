import { createSlice } from '@reduxjs/toolkit';
import { Constants } from '../../../../../helpers';

export const initialCreateDistrict = {
  countryId: '2',
  stateId: '',
  stateName: '',
  districtName: '',
  siteName: '',
  ncesDistrictId: '',
  username: '',
  password: '',
  firstname: '',
  email: '',
  groupId: '2'
};

export const initialFilter = {
  filter: {
    username: '',
    countryId: '',
    stateId: ''
  },
  sortOrder: 'desc',
  sortField: 'id',
  pageNumber: 1,
  pageSize: 10
};

export const callTypes = {
  action: 'action',
  list: 'list'
};

const initialDistrictState = {
  actionsLoading: false,
  listLoading: false,
  lastError: undefined,
  lastInfo: undefined,
  totalCount: 0,
  entities: undefined,
  sourceEntities: undefined,
  currentAction: undefined,
  currentDistricts: undefined,
  districtForEdit: undefined,
  startSort: undefined,
  dirty: false,
  listFilter: initialFilter
};

export const districtsSlice = createSlice({
  name: 'districts',
  initialState: initialDistrictState,
  reducers: {
    reinitialize: (state, action) => ({ ...initialDistrictState }),
    storeFormStatus: (state, action) => {
      state.dirty = action.payload.dirty;
    },
    startCall: (state, action) => {
      const { callType } = action.payload;
      state.lastError = null;
      if (callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    catchError: (state, action) => {
      const { lastError, callType } = action.payload;
      state.lastError = lastError;
      if (callType === callTypes.list) {
        state.listLoading = false;
        if (lastError.errorKeys && lastError.errorKeys.find((e) => e === Constants.PERMISSION_DENIED)) {
          state.entities = null;
          state.sourceEntities = null;
          state.totalCount = 0;
        }
      } else {
        state.actionsLoading = false;
        state.currentAction = null;
        state.currentDistricts = null;
      }
    },
    setupStartSort: (state, action) => {
      state.startSort = action.payload.startSort;
    },
    districtsTableSorted: (state, action) => {
      state.entities = action.payload.entities;
      state.listFilter = action.payload.listFilter;
    },
    districtsFetched: (state, action) => {
      const { entities, sourceEntities, totalCount, listFilter } = action.payload;
      state.entities = entities;
      state.sourceEntities = sourceEntities;
      state.totalCount = totalCount;
      state.listFilter = listFilter;
      state.listLoading = false;
      state.lastError = null;
      if (!state.lastInfo) {
        state.dirty = false;
        state.currentAction = null;
        state.currentDistricts = null;
      } else {
        state.lastInfo = null;
      }
    },
    districtFetched: (state, action) => {
      const { districtForEdit } = action.payload;
      state.lastError = null;
      state.lastInfo = null;
      state.actionsLoading = false;
      state.districtForEdit = districtForEdit;
    },
    districtDeleted: (state, action) => {
      const { currentAction, districtId } = action.payload;
      state.lastError = null;
      state.lastInfo = true;
      state.actionsLoading = false;
      state.currentAction = currentAction;
      state.currentDistricts = state.entities.find((item) => item.id === districtId)?.districtName;
    },
    districtSaved: (state, action) => {
      const { currentAction, districtName } = action.payload;
      state.lastError = null;
      state.lastInfo = true;
      state.actionsLoading = false;
      state.currentAction = currentAction;
      state.currentDistricts = districtName;
      state.districtForEdit = null;
    },
    clearDistrictFormData: (state, action) => {
      state.lastError = null;
      state.lastInfo = null;
      state.actionsLoading = false;
      state.currentAction = null;
      state.currentDistricts = null;
      state.districtForEdit = null;
    }
  }
});
