import { createSlice } from '@reduxjs/toolkit';

export const callTypes = {
  list: 'list',
  action: 'action'
};

export const initialFilter = {
  filter: {
    firstname: '',
    lastname: '',
    username: '',
    email: '',
    gender: '',
    address: '',
    phone: '',
    active: ''
  },
  sortOrder: 'desc',
  sortField: 'id',
  pageNumber: 1,
  pageSize: 10
};

const initialParentsState = {
  lastInfo: undefined,
  lastError: undefined,
  startSort: undefined,
  listLoading: false,
  actionsLoading: false,
  dirty: false,
  totalCount: 0,
  currentAction: undefined,
  currentParents: undefined,
  emailSent: undefined,
  parentForEdit: undefined,
  entities: undefined,
  sourceEntities: undefined,
  listFilter: initialFilter
};

export const parentsSlice = createSlice({
  name: 'parents',
  initialState: initialParentsState,
  reducers: {
    reinitialize: (state, action) => ({ ...initialParentsState }),
    storeFormStatus: (state, action) => {
      state.dirty = action.payload.dirty;
    },
    setupSortTable: (state, action) => {
      state.startSort = action.payload.startSort;
    },
    startCall: (state, action) => {
      state.lastError = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
        state.currentAction = null;
        state.currentParents = null;
        state.emailSent = null;
      }
    },
    endCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    catchError: (state, action) => {
      const { lastError, callType } = action.payload;
      state.lastInfo = null;
      state.lastError = lastError;
      if (callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    parentsSorted: (state, action) => {
      const { entities, listFilter } = action.payload;
      state.entities = entities;
      state.listFilter = listFilter;
    },
    parentsFetched: (state, action) => {
      const { totalCount, entities, sourceEntities, listFilter } = action.payload;
      state.lastError = null;
      state.listLoading = false;
      state.listFilter = listFilter;
      state.entities = entities;
      state.totalCount = totalCount;
      state.sourceEntities = sourceEntities;
      if (!state.lastInfo) {
        state.currentAction = null;
        state.currentParents = null;
        state.emailSent = null;
      } else {
        state.lastInfo = null;
      }
    },
    parentFetched: (state, action) => {
      const { parentForEdit } = action.payload;
      state.lastInfo = null;
      state.lastError = null;
      state.actionsLoading = false;
      state.parentForEdit = parentForEdit;
    },
    parentDeleted: (state, action) => {
      const { currentAction, parentId } = action.payload;
      state.lastError = null;
      state.lastInfo = true;
      state.actionsLoading = false;
      state.currentAction = currentAction;
      const item = state.entities.find((el) => el.parentId === parentId);
      state.currentParents = `${item.lastname} ${item.firstname}`;
    },
    parentSave: (state, action) => {
      const { currentAction, parentName } = action.payload;
      state.lastError = null;
      state.lastInfo = true;
      state.currentAction = currentAction;
      state.currentParents = parentName;
      state.actionsLoading = false;
    },
    parentAndKidCreated: (state, action) => {
      const { currentAction, emailSent } = action.payload;
      state.lastError = null;
      state.lastInfo = true;
      state.currentAction = currentAction;
      state.emailSent = emailSent;
      state.actionsLoading = false;
    }
  }
});
