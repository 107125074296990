const Constants = {
  STORAGE_KEY: 'pii_key',
  ERROR_COLOR: '#f018a6',
  GRADES: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  CHALLENGE_LEVEL: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  PRACTICE_LEVEL: [1, 2, 3, 4, 5, 6],
  SUBSCRIPTIONS: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
  USER_TYPES: { SCHOOL: 4, DISTRICT: 5, TOP: 0 },
  CURRENT_ACTIONS: {
    DELETE: 'delete',
    CREATE: 'create',
    UPDATE: 'update'
  },
  GROUP_YESNO: {
    YES: '1',
    NO: '0'
  },
  FORM_TYPE: {
    EXPIRATION: '1',
    MATH_WORLD: '2',
    ENGLISH_WORLD: '3'
  },
  BUILDING: { MATH_WORLD: '4', ENGLISH_WORLD: '2', BRITANNICA: '3' },
  SCHOOL_TYPES: [
    { id: '0', name: '' },
    { id: '1', name: 'Reading' }
  ],
  WEEK_DATE_FORMAT: 'ddd, MMM Do',
  WEEK_DATE_FORMAT_ZH: 'dddd MMMDo',
  UI_DATE_FORMAT: 'DD/MM/yyyy',
  DATE_TIME_FORMAT: 'yyyy-MM-DD HH:mm',
  DB_DATE_FORMAT: 'yyyy-MM-DD',
  HOUR_MINUTE_FORMAT: 'HH:mm:ss',
  HK_PHONE_FORMAT: /^(852)?(5[1234569]\d{6}|6\d{7}|9[0-8]\d{6})$/,
  HK_LANDLINE_FORMAT: /^(852)?(2[1-9]|3[145679])\d{6}$/,
  PURE_NUMBER_ALLOW_KEYS: /^[0-9]+$/,
  TEL_ALLOW_KEYS: /(^([0-9\\(\\)\\/\\+ \\-]*)$)/,
  NUMBER_ALLOW_KEYS: /^[0-9.]+$/,
  NORMAL_ALLOW_KEYS: /^[A-Za-z0-9-_.]+$/,
  NORMAL_PLUS_ALLOW_KEYS: /^[A-Za-z0-9-_. @#$*]+$/,
  SCHOOL_NAME_NOT_ALLOW_KEYS: /^[^[\]<>?/\\;:"{}£€|=+*&^%$#@!~`·！￥……（）——【】「」、‘’”“；：。，、？》《]+$/,
  SPECAIL_CHARACTERS: /^[^[\]<>.?/\\;:"'{}£€|=+()*&^%$#@!~`·！￥……（）——【】「」、‘’”“；：。，、？》《]+$/,
  CHINESE_REGEXP: /[\u4e00-\u9fa5]/,
  DEFAULT_MIN_DATE: '1900-01-01',
  ACCOUNT_CREATION_WIZARD: {
    NUM_TEACHER: { MAX: 20, MIN: 1 },
    NUM_CLASS: { MIN: 1, MAX: 10 },
    NUM_STUDENT: { MIN: 1, MAX: 100 }
  },
  CLASSNO_LENGTH: {
    MAX: 10
  },
  USERNAME_LENGTH: {
    MAX: 50
  },
  PASSWORD_LENGTH: {
    MAX: 50
  },
  FIRSTNAME_LENGTH: {
    MAX: 30
  },
  STUDENT_FIRSTNAME_LENGTH: {
    MAX: 50
  },
  LASTNAME_LENGTH: {
    MAX: 20
  },
  STUDENT_LASTNAME_LENGTH: {
    MAX: 30
  },
  CLASSNAME_LENGTH: {
    MAX: 20
  },
  STUDENTID_LENGTH: {
    MAX: 20
  },
  STUDENTNAME_LENGTH: {
    MAX: 30
  },
  SCHOOL_NAME_LENGTH: {
    MIN: 5,
    MAX: 100
  },
  CUSTOMID_LENGTH: {
    MIN: 5,
    MAX: 50
  },
  COMPETITION: {
    START: '1'
  },
  USER: 'user',
  SITE: 'site',
  HOME: 'home',
  INFO: 'info',
  RESULT: 'result',
  COUNTRIES: 'countrys',
  COUNTRY: 'country',
  STATES: 'states',
  STATE: 'state',
  DISTRICTS: 'districts',
  DISTRICT: 'district',
  SCHOOLS: 'schools',
  SCHOOL: 'school',
  CLASSES: 'classes',
  CLAZZ: 'clazz',
  TEACHERS: 'teachers',
  TEACHER: 'teacher',
  STUDENTS: 'students',
  STUDENT: 'student',
  CONSUMERS: 'consumers',
  CONSUMER: 'consumer',
  TOPICS: 'topics',
  TOPIC: 'topic',
  CLASS_STUDENTS: 'classStudents',
  CLASS_STUDENT: 'classStudent',
  ASSIGNMENTS: 'assignments',
  ASSIGNMENT: 'assignment',
  PERMISSION_DENIED: 'general.permission.denied',
  SESSION_INVALID: 'general.session.invalid',
  MESSAGE_KEY: 'messageKey',
  MESSAGE_MSG: 'message',
  TCLASSES: 'tclasses',
  TCLASS: 'tclass',
  TEMPLATES: 'templates',
  TEMP: 'temp',
  PERSONS: 'persons',
  PERSON: 'person',
  PARENTS: 'parents',
  PARENT: 'parent',
  KIDS: 'kids',
  KID: 'kid',
  BANKS: 'tbanks',
  BANK: 'tbank',
  PAYMENTS: 'payms',
  PAYMENT: 'paym',
  CATEGORIES: 'tCategorys',
  CATEGORY: 'tCategory',
  GROUPS: 'groups',
  GROUP: 'group',
  LANGUAGES: 'languages',
  LANGUAGE: 'language',
  ERRORS: 'errors',
  ERROR: 'error',
  EMAIL_SENT: 'sendisn',
  TRUE: 'true',
  FALSE: 'false',
  STATUS: 'status',
  STATUS_SUCCESS: '1',
  STATUS_FAILURE: '0',
  TRANSACTION: 'transaction',
  SERVER_ERROR: 'general.try.again',
  MAX_NUM_RECORDS_PROCESSED_IN_EXCEL: 200,
  MAX_NUM_RECORDS_PROCESSED_IN_EXCEL_CLASS_STUDNET: 500,

  ADMIN_MESA_MODULE_ROLE: 'Admin Mesa Module Role',
  ACCESS_MESA_MODULE_ROLE: 'Access Mesa Module Role',
  UPLOAD_MESA_FILE_ROLE: 'Upload Mesa File Role',

  COPY_ALL_TEST_ROLE: 'Copy All Test Role',

  SET_COMPETITION_ROLE: 'Set Competition Role',
  SHOW_ENGLISH_BUDING: 'Show English Building Role',

  ACCESS_CLASS_MODULE_ROLE: 'Access Class Module Role',
  CREATE_CLASS_ROLE: 'Create Class Role',
  SEARCH_CLASS_ROLE: 'Search Class Role',
  UPDATE_CLASS_ROLE: 'Update Class Role',
  DELETE_CLASS_ROLE: 'Delete Class Role',
  ASSIGN_CLASS_ROLE: 'Assign Class Role',
  ADMIN_CLASS_ROLE: 'Admin Class Role',

  ACCESS_STUDENT_MODULE_ROLE: 'Access Student Module Role',
  CREATE_STUDENT_ROLE: 'Create Student Role',
  SEARCH_STUDENT_ROLE: 'Search Student Role',
  UPDATE_STUDENT_ROLE: 'Update Student Role',
  DELETE_STUDENT_ROLE: 'Delete Student Role',
  ADMIN_STUDENT_ROLE: 'Admin Student Role',
  SET_STUDENT_ENGLISH_WORLD_ROLE: 'Set Student English World Level Role',

  RUN_REPORT_ROLE: 'Run Report Role',

  ACCESS_TEACHER_MODULE_ROLE: 'Access Teacher Module Role',
  CREATE_TEACHER_ROLE: 'Create Teacher Role',
  SEARCH_TEACHER_ROLE: 'Search Teacher Role',
  UPDATE_TEACHER_ROLE: 'Update Teacher Role',
  DELETE_TEACHER_ROLE: 'Delete Teacher Role',
  DELETE_TEACHER_ASSIGNED_ROLE: 'Delete Teacher Assigned Role',
  COPY_ASSIGNMENT_ROLE: 'Copy Assignment Role',
  ADMIN_TEACHER_ROLE: 'Admin Teacher Role',

  ACCESS_SCHOOL_MODULE_ROLE: 'Access School Module Role',
  CREATE_SCHOOL_ROLE: 'Create School Role',
  SEARCH_SCHOOL_ROLE: 'Search School Role',
  EXPIRATION_DATE_ROLE: 'Expiration Date Role',
  SET_ENGLISH_WORLD_ROLE: 'Set English World Role',
  SET_MATH_WORLD_ROLE: 'Set Math World Role',
  UPDATE_SCHOOL_ROLE: 'Update School Role',
  DELETE_SCHOOL_ROLE: 'Delete School Role',
  ADMIN_SCHOOL_ROLE: 'Admin School Role',

  ACCESS_DISTRICT_MODULE_ROLE: 'Access District Module Role',
  CREATE_DISTRICT_ROLE: 'Create District Role',
  SEARCH_DISTRICT_ROLE: 'Search District Role',
  UPDATE_DISTRICT_ROLE: 'Update District Role',
  DELETE_DISTRICT_ROLE: 'Delete District Role',
  ADMIN_DISTRICT_ROLE: 'Admin District Role',

  SUPER_ADMIN_ROLE: 'Super Admin Role',

  ACCESS_CLASSROOM_MODULE_ROLE: 'Access Classroom Module Role',
  CREATE_CLASSROOM_ROLE: 'Create Classroom Role',
  SEARCH_CLASSROOM_ROLE: 'Search Classroom Role',
  UPDATE_CLASSROOM_ROLE: 'Update Classroom Role',
  DELETE_CLASSROOM_ROLE: 'Delete Classroom Role',
  ADMIN_CLASSROOM_ROLE: 'Admin Classroom Role',

  ACCESS_LIBRARY_MODULE_ROLE: 'Access Library Module Role',
  CREATE_LIBRARY_ROLE: 'Create Library Role',
  SEARCH_LIBRARY_ROLE: 'Search Library Role',
  UPDATE_LIBRARY_ROLE: 'Update Library Role',
  DELETE_LIBRARY_ROLE: 'Delete Library Role',
  ADMIN_LIBRARY_ROLE: 'Admin Library Role',

  ADMIN_HOME_ROLE: 'Admin Home Role',

  ADMIN_UTILITY_ROLE: 'Admin Utility Role',
  ACCESS_UTILITY_ROLE: 'Access Utility Role',

  EB_ADMIN_SCHOOL_ROLE: 'EB Admin School Role',
  EB_ADMIN_DISTRICT_ROLE: 'EB Admin District Role',
  EB_ADMIN_TEACHER_ROLE: 'EB Admin Teacher Role',
  EB_ADMIN_CLASS_ROLE: 'EB Admin Class Role',
  EB_ADMIN_STUDENT_ROLE: 'EB Admin Student Role',
  ADMIN_QB_ROLE: 'Admin QB Role',

  ADMIN_TRANSACTION_ROLE: 'Admin Transaction Role',
  SEARCH_TRANSACTION_ROLE: 'Search Transaction Role',
  CREATE_TRANSACTION_ROLE: 'Create Transaction Role',
  UPDATE_TRANSACTION_ROLE: 'Update Transaction Role',
  DELETE_TRANSACTION_ROLE: 'Delete Transaction Role',
  ACCESS_TRANSACTION_MODULE_ROLE: 'Access Transaction Module Role',

  ADMIN_CURRICULUM_ROLE: 'Admin Curriculum Role',
  SEARCH_CURRICULUM_ROLE: 'Search Curriculum Role',
  UPDATE_CURRICULUM_ROLE: 'Update Curriculum Role',
  ACCESS_CURRICULUM_MODULE_ROLE: 'Access Curriculum Module Role',

  EB_ADMIN_HOME_ROLE: 'EB Admin Home Role',
  SEARCH_HOME_ROLE: 'Search Home Role',
  CREATE_HOME_ROLE: 'Create Home Role',
  UPDATE_HOME_ROLE: 'Update Home Role',
  DELETE_HOME_ROLE: 'Delete Home Role',
  ACCESS_HOME_MODULE_ROLE: 'Access Home Module Role',

  ADMIN_ASSIGNBUCKET_ROLE: 'Admin Assignbucket Role',
  ACCESS_ASSIGNMENTBUCKET_ROLE: 'Access Assignmentbucket Role'
};

export { Constants };
