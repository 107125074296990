import React from 'react';

export const ActionButton = ({
  children,
  loading,
  onClick = () => {},
  type = 'button',
  disabled = false,
  className = ''
}) => {
  return (
    <button
      type={type}
      disabled={loading || disabled}
      onClick={onClick}
      className={`btn btn-elevate ${(loading || disabled) && 'cursor-default'} ${className}`}
    >
      {children}
      {loading && <span className="ml-3 spinner spinner-white"></span>}
    </button>
  );
};
