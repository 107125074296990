import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';

export const FormStatusListener = ({ action }) => {
  const { dirty } = useFormikContext();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(action({ dirty }));
  }, [dirty, action, dispatch]);

  return null;
};
