import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import * as auth from '../app/modules/Auth/redux/authRedux';
import * as general from '../app/context/redux/generalRedux';
import { schoolsSlice } from '../app/modules/School/redux/slices/schoolsSlice';
import { teachersSlice } from '../app/modules/Teacher/redux/slices/teachersSlice';
import { classesSlice } from '../app/modules/Class/redux/slices/classesSlice';
import { studentsSlice } from '../app/modules/Student/redux/slices/studentsSlice';
import { consumerSlice } from '../app/modules/Consumer/redux/slices/consumerSlice';
import { parentsSlice } from '../app/modules/Consumer/redux/slices/parentsSlice';
import { kidsSlice } from '../app/modules/Consumer/redux/slices/kidsSlice';
import { utilitySlice } from '../app/modules/Utility/redux/slices/utilitySlice';
import { districtsSlice } from '../app/modules/District/redux/slices/districtsSlice';

const appReducer = combineReducers({
  auth: auth.reducer,
  general: general.reducer,
  schools: schoolsSlice.reducer,
  teachers: teachersSlice.reducer,
  classes: classesSlice.reducer,
  students: studentsSlice.reducer,
  consumer: consumerSlice.reducer,
  parents: parentsSlice.reducer,
  kids: kidsSlice.reducer,
  utility: utilitySlice.reducer,
  districts: districtsSlice.reducer
});

export const rootReducer = (state, action) => {
  // keep auth for authRedux LOGOUT and persist to clear storage
  // clear other redux states
  if (action.type === auth.actionTypes.LOGOUT) {
    const { auth } = state;
    return appReducer({ auth }, action);
  }
  return appReducer(state, action);
};

export function* rootSaga() {
  yield all([auth.saga()]);
}
