import { unicodeConvert } from '../../../helpers';

const initialValues = {
  countries: undefined,
  banks: undefined,
  categories: undefined,
  groupTypes: undefined,
  languages: undefined,
  paymentMethods: undefined,
  salers: undefined,
  templates: undefined,
  templateCategories: undefined,
  states: undefined,
  statesByCountry: undefined,
  districts: undefined,
  schools: undefined,
  teachers: undefined,
  classes: undefined,
  students: undefined,
  extraStudents: undefined,
  sites: undefined
};

export const actionTypes = {
  FETCH_COUNTRIES: '[Fetch Countries] Action',
  FETCH_BANKS: '[Fetch Banks] Action',
  FETCH_CATEGORIES: '[Fetch Categories] Action',
  FETCH_GROUP_TYPES: '[Fetch Group Types] Action',
  FETCH_LANGUAGES: '[Fetch Languages] Action',
  FETCH_PAYMENT_METHODS: '[Fetch Payment Methods] Action',
  FETCH_SALERS: '[Fetch Salers] Action',
  FETCH_TEMPLATES: '[Fetch Templates] Action',
  FETCH_TEMPLATE_CATEGORIES: '[Fetch Template Categories] Action',
  FETCH_STATES: '[Fetch States] Action',
  FETCH_STATES_BY_COUNTRY: '[Fetch States By Country] Action',
  FETCH_DISTRICTS: '[Fetch Districts] Action',
  FETCH_SCHOOLS: '[Fetch Schools] Action',
  FETCH_TEACHERS: '[Fetch Teachers] Action',
  FETCH_CLASSES: '[Fetch Classes] Action',
  FETCH_STUDENTS: '[Fetch Students] Action',
  FETCH_EXTRA_STUDENTS: '[Fetch ExtraStudents] Action',
  FETCH_SITES: '[Fetch Sites] Action'
};

export const reducer = (state = initialValues, action = {}) => {
  switch (action.type) {
    case actionTypes.FETCH_COUNTRIES: {
      return { ...state, countries: action.entities };
    }
    case actionTypes.FETCH_BANKS: {
      return { ...state, banks: action.entities };
    }
    case actionTypes.FETCH_CATEGORIES: {
      return { ...state, categories: action.entities };
    }
    case actionTypes.FETCH_GROUP_TYPES: {
      return { ...state, groupTypes: action.entities };
    }
    case actionTypes.FETCH_LANGUAGES: {
      return { ...state, languages: action.entities };
    }
    case actionTypes.FETCH_PAYMENT_METHODS: {
      return { ...state, paymentMethods: action.entities };
    }
    case actionTypes.FETCH_SALERS: {
      return { ...state, salers: action.entities };
    }
    case actionTypes.FETCH_TEMPLATES: {
      return { ...state, templates: action.entities };
    }
    case actionTypes.FETCH_TEMPLATE_CATEGORIES: {
      return { ...state, templateCategories: action.entities };
    }
    case actionTypes.FETCH_STATES: {
      return { ...state, states: action.entities };
    }
    case actionTypes.FETCH_STATES_BY_COUNTRY: {
      return { ...state, statesByCountry: action.entities };
    }
    case actionTypes.FETCH_DISTRICTS: {
      return { ...state, districts: action.entities };
    }
    case actionTypes.FETCH_SCHOOLS: {
      return { ...state, schools: action.entities };
    }
    case actionTypes.FETCH_TEACHERS: {
      return { ...state, teachers: action.entities };
    }
    case actionTypes.FETCH_CLASSES: {
      return {
        ...state,
        // convert &#x unicode to Chinese characters
        classes: action.entities.map((el) => ({
          ...el,
          className: unicodeConvert(el.className),
          teacherName: unicodeConvert(el.teacherName)
        }))
      };
    }
    case actionTypes.FETCH_STUDENTS: {
      return { ...state, students: action.entities };
    }
    case actionTypes.FETCH_EXTRA_STUDENTS: {
      return { ...state, extraStudents: action.entities };
    }
    case actionTypes.FETCH_SITES: {
      return { ...state, sites: action.entities };
    }
    default:
      return state;
  }
};
