import React from 'react';
import { useFormikContext, useField } from 'formik';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Constants } from '../../../helpers';
import { useIntl } from 'react-intl';

export const MaterialTimePicker = ({ skipTouched, ...props }) => {
  const { formatMessage } = useIntl();
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  let time;
  if ((skipTouched && meta.error) || (!skipTouched && meta.touched && meta.error)) {
    if (field.value === null) {
      time = '';
    } else {
      time = field.value;
    }
  } else if (field.value) {
    time = field.value;
  } else {
    time = null;
  }

  const error = skipTouched ? !!meta.error : !!(meta.touched && meta.error);

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardTimePicker
          {...props}
          {...field}
          clearable
          autoOk
          value={time}
          error={error}
          ampm={false}
          invalidDateMessage={meta.error ? '' : formatMessage({ id: 'general.invalid.time' })}
          onChange={(value) => setFieldValue(field.name, value === null ? '' : value)}
        />
      </MuiPickersUtilsProvider>
      {skipTouched && meta.error && <div style={{ color: Constants.ERROR_COLOR }}>{meta.error}</div>}
      {meta.touched && meta.error && <div style={{ color: Constants.ERROR_COLOR }}>{meta.error}</div>}
    </>
  );
};
