/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
//import axios from 'axios';
//import * as _redux from "./redux";
import store, { persistor } from './redux/store';
import App from './app/App';
import './index.scss'; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import './assets/plugins/keenthemes-icons/font/ki.css';
import 'socicon/css/socicon.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/plugins/flaticon/flaticon.css';
import './assets/plugins/flaticon2/flaticon.css';
// Datepicker
import 'react-datepicker/dist/react-datepicker.css';
import { LayoutProvider, SplashScreenProvider, SubheaderProvider } from './layout';
import { ContextI18nProvider } from './i18n';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic  mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/* const mock = _redux.mockAxios(axios); */
/**
 * Inject interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
//No token in backend
//_redux.setupAxios(axios, store);

ReactDOM.render(
  <ContextI18nProvider>
    <LayoutProvider>
      <SubheaderProvider>
        <SplashScreenProvider>
          <App store={store} persistor={persistor} basename={PUBLIC_URL} />
        </SplashScreenProvider>
      </SubheaderProvider>
    </LayoutProvider>
  </ContextI18nProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
