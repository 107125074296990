import { createSlice } from '@reduxjs/toolkit';
import { Constants } from '../../../../../helpers';

export const initialAddParent = {
  kidId: '',
  kidUsername: '',
  parentUsername: '',
  parentPassword: '',
  parentFirstname: '',
  parentLastname: '',
  email: '',
  paymentMethod: '1',
  paymentName: 'PPS',
  addr1: '',
  addr2: '',
  phoneMain: '',
  phoneAlt: '',
  countryId: 2,
  city: '',
  gender: 'M',
  parentLanguageId: 2,
  subscriptionLength: 12,
  amount: 600,
  transactionInfo: {
    depositdate: '',
    chequedate: '',
    cheque: '',
    bankId: '',
    nameoncheck: '',
    salesmanId: '',
    drn: ''
  }
};

export const initialCreateStudent = {
  schoolId: '', //required
  firstname: '', //required
  lastname: '',
  username: '', // required
  password: '', //required
  grade: 0,
  gender: 'M', //male
  studentId: '',
  email: '',
  birthday: '',
  languageId: '2' //tranditional Chinese
};

export const callTypes = {
  list: 'list',
  action: 'action'
};

export const initialFilter = {
  filter: {
    firstname: '',
    lastname: '',
    studentId: '',
    schoolId: '',
    email: '',
    grade: '',
    username: ''
  },
  sortField: 'id',
  sortOrder: 'desc',
  pageSize: 10,
  pageNumber: 1
};

const initialStudentsState = {
  listLoading: undefined,
  actionsLoading: undefined,
  lastInfo: undefined,
  lastError: undefined,
  studentForEdit: undefined,
  totalCount: 0,
  startSort: false,
  entities: undefined,
  sourceEntities: undefined,
  dirty: undefined,
  currentAction: undefined,
  currentStudents: undefined,
  studentsForExcel: undefined,
  distrctSchools: undefined,
  schoolChanged: undefined,
  parentAdded: undefined,
  listFilter: initialFilter
};

export const studentsSlice = createSlice({
  name: 'students',
  initialState: initialStudentsState,
  reducers: {
    reinitialize: (state, action) => ({ ...initialStudentsState }),
    storeFormStatus: (state, action) => {
      state.dirty = action.payload.dirty;
    },
    startCall: (state, action) => {
      state.lastError = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
        state.currentAction = null;
        state.currentParents = null;
        state.schoolChanged = false;
        state.parentAdded = null;
        state.studentsForExcel = null;
      }
    },
    endCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    catchError: (state, action) => {
      const { lastError, callType } = action.payload;
      state.lastError = lastError;
      if (callType === callTypes.list) {
        state.listLoading = false;
        if (lastError.errorKeys && lastError.errorKeys.find((e) => e === Constants.PERMISSION_DENIED)) {
          state.entities = null;
          state.sourceEntities = null;
          state.totalCount = 0;
        }
      } else {
        state.actionsLoading = false;
      }
    },
    setupSortTable: (state, action) => {
      state.startSort = action.payload.startSort;
    },
    studentsSorted: (state, action) => {
      const { entities, listFilter } = action.payload;
      state.entities = entities;
      state.listFilter = listFilter;
    },
    studentsFetched: (state, action) => {
      const { entities, sourceEntities, totalCount, listFilter } = action.payload;
      state.lastError = null;
      state.listLoading = false;
      state.entities = entities;
      state.sourceEntities = sourceEntities;
      state.totalCount = totalCount;
      state.listFilter = listFilter;
      if (!state.lastInfo) {
        state.dirty = false;
        state.currentAction = null;
        state.currentStudents = null;
        state.schoolChanged = false;
        state.parentAdded = null;
        state.studentsForExcel = null;
      } else {
        state.lastInfo = null;
      }
    },
    studentFetched: (state, action) => {
      state.lastError = null;
      state.actionsLoading = false;
      state.studentForEdit = action.payload.studentForEdit;
    },
    studentDeleted: (state, action) => {
      const { currentAction, userId } = action.payload;
      state.lastInfo = true;
      state.lastError = null;
      state.actionsLoading = false;
      state.currentAction = currentAction;
      const student = state.entities.find((el) => el.userId === userId);
      state.currentStudents = `${student.lastname} ${student.firstname}`;
      state.entities = state.entities.filter((el) => el.userId !== userId);
      state.sourceEntities = state.sourceEntities.filter((el) => el.userId !== userId);
    },
    studentCreatedOrUpdated: (state, action) => {
      const { currentAction, currentStudents } = action.payload;
      state.lastError = null;
      state.lastInfo = true;
      state.actionsLoading = false;
      state.currentAction = currentAction;
      state.currentStudents = currentStudents;
    },
    studentsExcelUploaded: (state, action) => {
      const { dirty, studentsForExcel, lastError } = action.payload;
      state.lastInfo = true;
      state.lastError = lastError;
      state.dirty = dirty;
      state.actionsLoading = false;
      state.studentsForExcel = studentsForExcel;
    },
    distrctSchoolsFetched: (state, action) => {
      state.distrctSchools = action.payload.distrctSchools;
      state.actionsLoading = false;
      state.lastError = null;
    },
    schoolChanged: (state, action) => {
      state.schoolChanged = action.payload.schoolChanged;
      state.actionsLoading = false;
      state.lastError = null;
      state.lastInfo = true;
    },
    parentAdded: (state, action) => {
      state.parentAdded = action.payload.parentAdded;
      state.actionsLoading = false;
      state.lastError = null;
      state.lastInfo = true;
    }
  }
});
