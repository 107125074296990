import { Constants } from './Constants';

export const number2bool = (value) => !!parseInt(value);

export const bool2number = (value) => (value ? 1 : 0);

//user types
export const userTypes = [
  { id: 1, name: 'Kid' },
  { id: 2, name: 'Parent' },
  { id: 3, name: 'Teacher' },
  { id: 4, name: 'School Admin' },
  { id: 5, name: 'District Admin' }
];

// gender
export const genders = (formatMessage) => [
  { id: 'M', name: formatMessage({ id: 'general.male' }) },
  { id: 'F', name: formatMessage({ id: 'general.female' }) }
];

// student sorts
export const studentSorts = (formatMessage) => [
  ...fieldSorts(formatMessage),
  { id: 'username', name: formatMessage({ id: 'general.username' }) },
  { id: 'date_joined', name: formatMessage({ id: 'teacher.form.creation.date' }) }
];

export const fieldSorts = (formatMessage) => [
  { id: 'firstname', name: formatMessage({ id: 'general.firstname' }) },
  { id: 'lastname', name: formatMessage({ id: 'general.lastname' }) },
  { id: 'student_id', name: formatMessage({ id: 'general.student.id' }) }
];

// default sortBy
export const defaultSorted = [{ dataField: 'id', order: 'desc' }];

// page sizes
export const sizePerPageList = [
  { text: '5', value: 5 },
  { text: '10', value: 10 },
  { text: '20', value: 20 },
  { text: '30', value: 30 }
];

// building types
export const buildingTypes = (formatMessage) => [
  { id: Constants.BUILDING.MATH_WORLD, name: formatMessage({ id: 'general.math.world' }) },
  { id: Constants.BUILDING.ENGLISH_WORLD, name: formatMessage({ id: 'general.english.world' }) },
  { id: Constants.BUILDING.BRITANNICA, name: formatMessage({ id: 'general.britannica' }) }
];

// yes, no
export const yesNoIds = (formatMessage) => [
  { id: Constants.GROUP_YESNO.YES, name: formatMessage({ id: 'general.yes' }) },
  { id: Constants.GROUP_YESNO.NO, name: formatMessage({ id: 'general.no' }) }
];

export const yesNoValues = [
  { value: Constants.GROUP_YESNO.YES, label: 'general.yes' },
  { value: Constants.GROUP_YESNO.NO, label: 'general.no' }
];

// retreive key/value from  string
export const parseData = (str, splitter = '&') => new Map(str.split(splitter).map((kv) => kv.split('=')));

// axios upload progress
export const progressConfig = {
  onUploadProgress: (progressEvent) => {
    return Math.round((progressEvent.loaded * 100) / progressEvent.total);
  }
};

// hyper Index
export const hyperIndex = (key, obj, splitter = '.') =>
  key.split(splitter).length === 1 ? obj[key] : key.split(splitter).reduce((o, i) => o[i] || '', obj);

// sequence generator
export const sequenceGenerator = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, index) => start + index * step);

// create xml
export const createXml = (entity) =>
  Object.keys(entity)
    .map((key) => `<${key}>${entity[key]}</${key}>`)
    .join('');

// create formData
export const createFormData = (data, prefix) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    const myKey = prefix ? `${prefix}.${key}` : key;
    formData.append(myKey, typeof data[key] === 'string' ? data[key].trim() : data[key]);
  });
  return formData;
};
/* Backend errors structure
builder.errors {
    errorKeys.each {key ->
        error {
            messageKey(key) //i18n in React
        }
    }
    errorMessages.each {msg ->
        error {
            message(msg) //Server Error
        }
    }
}*/
export const findErrorMessage = (list) => {
  const builder = { errorKeys: [], errorMessages: [] };
  const errors = list.find((item) => item.name === Constants.ERRORS);
  const errorList = errors?.children?.filter((item) => item.name === Constants.ERROR);
  errorList?.forEach((error) => {
    const keyError = error.children?.find((item) => item.name === Constants.MESSAGE_KEY);
    if (keyError) {
      builder.errorKeys.push(keyError.value);
      if (keyError.value === Constants.SESSION_INVALID) builder.session_invalid = keyError.value;
    }

    const msgError = error.children?.find((item) => item.name === Constants.MESSAGE_MSG);
    if (msgError) {
      builder.errorMessages.push(msgError.value);
    }
  });

  return builder;
};

// transform list data to object
export const listToObject = (list, key = 'name', value = 'value') => {
  const reducer = (accumulator, currentValue) => {
    accumulator[currentValue[key]] = currentValue[value];
    return accumulator;
  };
  return list?.reduce(reducer, {});
};

// convert &#x unicode to Chinese characters
export const unicodeConvert = (str) => {
  // 一般可以先转换为标准 unicode 格式（有需要就添加：当返回的数据呈现太多\\\u 之类的时）
  str = unescape(str.replace(/\\u/g, '%u'));
  // 再对实体符进行转义
  // 有 x 则表示是16进制，$1 就是匹配是否有 x，$2 就是匹配出的第二个括号捕获到的内容，将 $2 以对应进制表示转换
  str = str.replace(/&#(x)?(\w+);/g, function ($, $1, $2) {
    return String.fromCharCode(parseInt($2, $1 ? 16 : 10));
  });

  return str;
};

// download file using HTML5 Blob
export const downloadBlob = (response, fileName, fileType = 'application/vnd.ms-excel') => {
  fileName = fileName ? fileName : response.headers['content-disposition'].split('filename')[1];
  const fileContents = new Blob([response.data], { type: fileType });
  saveBlobAsFile(fileName, fileContents);
};

// save HTML5 Blob
export const saveBlobAsFile = (fileName, fileContents) => {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // IE
    window.navigator.msSaveOrOpenBlob(fileContents, fileName);
  } else {
    const url = window.URL.createObjectURL(fileContents);
    downloadFileSilent(fileName, url);
  }
};

// download file using path, no Window/Tab
export const downloadFileSilent = (fileName, url) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName; // optional
  link.click();
  window.setTimeout(() => {
    // https://developer.mozilla.org/zh-CN/docs/Web/API/URL/revokeObjectURL
    window.URL.revokeObjectURL(url);
  }, 100);
};

// download file using new Window/Tab
export const downloadFile = (path) => {
  window.open(`${window.location.origin}${path}`);
};
