import React from 'react';
import { useLang } from './ContextI18nProvider';
import { IntlProvider } from 'react-intl';

import en from './messages/en';
import zh from './messages/zh';

const allMessages = { en, zh };
function Fragment(props) {
  return props.children || <span {...props} /> || null;
}

export function I18nProvider({ children }) {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages} textComponent={Fragment}>
      {children}
    </IntlProvider>
  );
}
