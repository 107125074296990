import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  boxWrap: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& .box': {
      margin: '0 auto',
      padding: '4rem 1.25rem',
      backgroundColor: 'white',
      width: '546px',
      borderRadius: '0.25rem',

      '& .d-flex': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      },

      '& .message': {
        textAlign: 'center',
        marginTop: '1.5rem',
        fontSize: '1.125rem'
      },

      '& .text-wrap': {
        position: 'relative',
        width: '100%',
        height: '7.1875rem',
        textAlign: 'center',
        zIndex: 3,

        '& h1': {
          position: 'absolute',
          textAlign: 'center',
          width: '100%',
          fontSize: '6rem',
          fontWeight: 700,
          margin: 0,
          animation: `$shake .6s ease-in-out infinite alternate`,
          userSelect: 'none',

          '&::before': {
            center: 'attr(data-t)',
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, .34em)',
            height: '.1em',
            width: '100%',
            lineHeight: '.5em',
            animation: `$scan .5s ease-in-out 4ms infinite alternate, $glitchAnim .3s ease-in-out infinite alternate`,
            overflow: 'hidden',
            opacity: '.7'
          },

          '&::after': {
            content: 'attr(data-t)',
            position: 'absolute',
            top: '-30px',
            left: '50%',
            transform: 'translate(-50%, .34em)',
            height: '.5em',
            width: '100%',
            lineHeight: '.1em',
            animation: `$scan 665ms ease-in-out 186ms infinite alternate, $glitchAnim .3s ease-in-out infinite alternate`,
            overflow: 'hidden',
            opacity: '.8'
          }
        }
      }
    }
  },
  '@media only screen and (max-width: 480px)': {
    '& .box': {
      width: '100%'
    }
  },
  '@keyframes scan': {
    '0%, 20%, to': {
      height: 0,
      transform: 'translate(-50%, .44em)'
    },
    '10%, 15%': {
      height: '1em',
      lineHeight: '.2em',
      transform: 'translate(-55%, .2em)'
    }
  },
  '@keyframes shake': {
    '0%, to': {
      transform: 'translate(-1px)'
    },
    '10%': {
      transform: 'translate(2px, 1px)'
    },
    '30%': {
      transform: 'translate(-3px, 2px)'
    },
    '35%': {
      transform: 'translate(2px, -3px)',
      filter: 'blur(4px)'
    },
    '45%': {
      transform: 'translate(2px, 2px) skewY(-8deg) scaleX(.96)',
      filter: 'blur(0)'
    },
    '50%': {
      transform: 'translate(-3px, 1px)'
    }
  },
  '@keyframes glitchAnim': {
    '0%': {
      clip: 'rect(8px, 9999px, 42px, 0)'
    },
    '10%': {
      clip: 'rect(8px, 9999px, 8px, 0)'
    },
    '20%': {
      clip: 'rect(88px, 9999px, 83px, 0)'
    },
    '30%': {
      clip: 'rect(42px, 9999px, 32px, 0)'
    },
    '40%': {
      clip: 'rect(45px, 9999px, 79px, 0)'
    },
    '50%': {
      clip: 'rect(43px, 9999px, 4px, 0)'
    },
    '60%': {
      clip: 'rect(74px, 9999px, 7px, 0)'
    },
    '70%': {
      clip: 'rect(81px, 9999px, 93px, 0)'
    },
    '80%': {
      clip: 'rect(9px, 9999px, 35px, 0)'
    },
    '90%': {
      clip: 'rect(85px, 9999px, 73px, 0)'
    },
    to: {
      clip: 'rect(85px, 9999px, 58px, 0)'
    }
  }
}));

export const ErrorPage = () => {
  const styles = useStyles();
  return (
    <div className={styles.boxWrap}>
      <div className="box">
        <div className="d-flex">
          <div className="text-wrap">
            <h1 data-t="404">404</h1>
          </div>
          <div className="message">
            <FormattedMessage id="general.page.inaccessible" />
          </div>
          <div className="mt-6">
            <a href="/" className="btn btn-primary">
              <FormattedMessage id="general.backto.home" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
