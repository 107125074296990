import React from 'react';
import { TilesWidget } from '../widgets';
import { FormattedMessage } from 'react-intl';
import '../../assets/sass/pages/login/classic/login-1.scss';
import { Card, CardBody } from '../controls';

export function DashboardInfo() {
  return (
    <Card className='h-100'>
      <CardBody>
        <TilesWidget />
        <div className='mt-5'>
          <p className='font-weight-bold font-size-lg'>
            <FormattedMessage id='general.planetii' />
            &nbsp;&nbsp;
            <FormattedMessage id='general.planetii.brief' />
          </p>
          <p className='font-weight-bold font-size-lg'>
            <FormattedMessage id='general.planetii' />
            &nbsp;&nbsp;
            <FormattedMessage id='general.planetii.desc' />
          </p>
          <p className='font-weight-bold font-size-lg'>
            <FormattedMessage id='general.planetii.desc2' />
          </p>
        </div>
      </CardBody>
    </Card>
  );
}
