import { createSlice } from '@reduxjs/toolkit';

export const callTypes = {
  list: 'list',
  action: 'action'
};

export const initialFilter = {
  filter: {
    firstname: '',
    lastname: '',
    username: '',
    email: '',
    piiLevel: '',
    gender: '',
    active: ''
  },
  sortOrder: 'desc',
  sortField: 'id',
  pageNumber: 1,
  pageSize: 10
};

const initialKidsState = {
  lastInfo: undefined,
  lastError: undefined,
  startSort: undefined,
  listLoading: false,
  actionsLoading: false,
  dirty: false,
  totalCount: 0,
  currentAction: undefined,
  currentKids: undefined,
  kidForEdit: undefined,
  entities: undefined,
  sourceEntities: undefined,
  listFilter: initialFilter,
  processedItem: undefined
};

export const kidsSlice = createSlice({
  name: 'kids',
  initialState: initialKidsState,
  reducers: {
    reinitialize: (state, action) => ({ ...initialKidsState }),
    storeFormStatus: (state, action) => {
      state.dirty = action.payload.dirty;
    },
    setupSortTable: (state, action) => {
      state.startSort = action.payload.startSort;
    },
    clearInfoAndErrorState: (state, action) => {
      state.lastInfo = null;
      state.lastError = null;
      state.processedItem = null;
      state.currentAction = null;
      state.currentKids = null;
    },
    startCall: (state, action) => {
      state.lastError = null;
      state.processedItem = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
        state.currentAction = null;
        state.currentKids = null;
      }
    },
    endCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    catchError: (state, action) => {
      const { lastError, callType } = action.payload;
      state.lastInfo = null;
      state.lastError = lastError;
      if (callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    kidsSorted: (state, action) => {
      const { entities, listFilter } = action.payload;
      state.entities = entities;
      state.listFilter = listFilter;
    },
    kidsFetched: (state, action) => {
      const { totalCount, entities, sourceEntities, listFilter } = action.payload;
      state.lastError = null;
      state.listLoading = false;
      state.listFilter = listFilter;
      state.entities = entities;
      state.totalCount = totalCount;
      state.sourceEntities = sourceEntities;
      if (!state.lastInfo) {
        state.currentAction = null;
        state.currentKids = null;
      } else {
        state.lastInfo = null;
      }
    },
    kidFetched: (state, action) => {
      const { kidForEdit } = action.payload;
      state.lastInfo = null;
      state.lastError = null;
      state.actionsLoading = false;
      state.kidForEdit = kidForEdit;
    },
    kidDeleted: (state, action) => {
      const { currentAction, kidId } = action.payload;
      state.lastError = null;
      state.lastInfo = true;
      state.actionsLoading = false;
      state.currentAction = currentAction;
      const item = state.entities.find((el) => el.kidId === kidId);
      state.currentKids = `${item.lastname} ${item.firstname}`;
    },
    kidSave: (state, action) => {
      const { currentAction, kidName } = action.payload;
      state.lastError = null;
      state.lastInfo = true;
      state.actionsLoading = false;
      state.currentAction = currentAction;
      state.currentKids = kidName;
    },
    accountReseted: (state, action) => {
      state.lastError = null;
      state.lastInfo = true;
      state.actionsLoading = false;
      state.processedItem = { type: 1 };
    },
    englishLevelSet: (state, action) => {
      state.lastError = null;
      state.lastInfo = true;
      state.actionsLoading = false;
      state.processedItem = { type: 2 };
    },
    accountRenewed: (state, action) => {
      state.lastError = null;
      state.lastInfo = true;
      state.actionsLoading = false;
      state.processedItem = { type: 3, emailSent: action.payload.emailSent };
    },
    parentChanged: (state, action) => {
      state.lastError = null;
      state.lastInfo = true;
      state.actionsLoading = false;
      state.processedItem = { type: 4 };
    }
  }
});
