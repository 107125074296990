import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { actionTypes } from '../../app/modules/Auth';
import { ActionButton } from './forms/ActionButton';

// detect session time out error in global, call in src/app/App.js
export function SessionInvalidDialog() {
  const dispatch = useDispatch();
  const { visible } = useSelector(({ auth }) => ({ visible: auth.sessionInvalid }), shallowEqual);

  const goLogin = () => {
    dispatch({ type: actionTypes.LOGOUT });
  };

  return (
    <Modal show={!!visible} onHide={() => {}} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <FormattedMessage id="general.confirmation" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormattedMessage id="general.session.invalid" />
      </Modal.Body>
      <Modal.Footer className="py-3 px-6">
        <div>
          <ActionButton className="btn-primary" onClick={goLogin}>
            <FormattedMessage id="general.confirm" />
          </ActionButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
