import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

export const ReinitializeState = (reinitialize) => {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (location.pathname && location.pathname.split('/').length === 2) {
      dispatch(reinitialize);
    }
  }, [location.pathname]);
};
