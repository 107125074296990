import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export function login(formData) {
  return axios.post(`${API_URL}/login`, formData);
}

export function requestPassword(email) {
  return axios.post(`${API_URL}/forgot-password`, { email });
}
