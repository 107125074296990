import React, { Suspense, lazy } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { LayoutSplashScreen, ContentRoute } from '../layout';
import { DashboardPage } from './pages/DashboardPage';

const AccountingPage = lazy(() => import('./modules/Accounting/pages/AccountingRoutes'));

const AssignmentPage = lazy(() => import('./modules/Assignment/pages/AssignmentRoutes'));

const ClassPage = lazy(() => import('./modules/Class/pages/ClassRoutes'));

const ConsumerPage = lazy(() => import('./modules/Consumer/pages/ConsumerRoutes'));

const CurriculumPage = lazy(() => import('./modules/Curriculum/pages/CurriculumRoutes'));

const DistrictPage = lazy(() => import('./modules/District/pages/DistrictRoutes'));

const PracPlusPage = lazy(() => import('./modules/PracPlus/pages/PracPlusRoutes'));

const PracticePage = lazy(() => import('./modules/Practice/pages/PracticeRoutes'));

const QbPage = lazy(() => import('./modules/QB/pages/QbRoutes'));

const ReportPage = lazy(() => import('./modules/Report/pages/ReportRoutes'));

const SchoolPage = lazy(() => import('./modules/School/pages/SchoolRoutes'));

const StudentPage = lazy(() => import('./modules/Student/pages/StudentRoutes'));

const TeacherPage = lazy(() => import('./modules/Teacher/pages/TeacherRoutes'));

const UtilityPage = lazy(() => import('./modules/Utility/pages/UtilityRoutes'));

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/accounting" component={AccountingPage} />
        <Route path="/assignment" component={AssignmentPage} />
        <Route path="/class" component={ClassPage} />
        <Route path="/consumer" component={ConsumerPage} />
        <Route path="/curriculum" component={CurriculumPage} />
        <Route path="/district" component={DistrictPage} />
        <Route path="/prac-plus" component={PracPlusPage} />
        <Route path="/practice" component={PracticePage} />
        <Route path="/qb" component={QbPage} />
        <Route path="/report" component={ReportPage} />
        <Route path="/school" component={SchoolPage} />
        <Route path="/student" component={StudentPage} />
        <Route path="/teacher" component={TeacherPage} />
        <Route path="/utility" component={UtilityPage} />
        <Redirect to="/error/404" />
      </Switch>
    </Suspense>
  );
}
