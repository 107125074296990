/* eslint-disable */
/* eslint-disable no-script-url */
import React from 'react';
import { useLocation } from 'react-router';
import { shallowEqual, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';
import { toAbsoluteUrl, checkIsActive, Constants } from '../../../../helpers';

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const { authToken } = useSelector((state) => state.auth, shallowEqual);

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open menu-item-not-hightlighted`
      : '';
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive('/dashboard', false)}`} aria-haspopup='true'>
          <NavLink className='menu-link' to='/dashboard'>
            <span className='svg-icon menu-icon'>
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className='menu-text'>
              <FormattedMessage id='menu.dashboard' />
            </span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Applications */}
        {/* begin::section */}
        <li className='menu-section '>
          <h4 className='menu-text'>
            <FormattedMessage id='menu.apps' />
          </h4>
          <i className='menu-icon flaticon-more-v2'></i>
        </li>
        {/* end:: section */}

        {/*begin: Utility :1 Level*/}
        {authToken?.find((item) => item.role === Constants.ACCESS_UTILITY_ROLE) && (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive('/utility', true)}`}
            aria-haspopup='true'
            data-menu-toggle='hover'
          >
            <NavLink className='menu-link menu-toggle' to='/utility'>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Tools/Tools.svg')} />
              </span>
              <span className='menu-text'>
                <FormattedMessage id='main.module.utility' />
              </span>
            </NavLink>
            <div className='menu-submenu'>
              <i className='menu-arrow' />
              <ul className='menu-subnav'>
                {/*begin::2 Level*/}
                <li className={`menu-item ${getMenuItemActive('/utility/user-info-lookup')}`} aria-haspopup='true'>
                  <NavLink className='menu-link' to='/utility/user-info-lookup'>
                    <i className='menu-bullet menu-bullet-dot'>
                      <span />
                    </i>
                    <span className='menu-text'>
                      <FormattedMessage id='utility.module.quick.info.lookup' />
                    </span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
              </ul>
            </div>
          </li>
        )}
        {/* end::1 Level */}

        {/*begin: District :1 Level*/}
        {authToken?.find((item) => item.role === Constants.ACCESS_DISTRICT_MODULE_ROLE) && (
          <li className={`menu-item ${getMenuItemActive('/district', false)}`} aria-haspopup='true'>
            <NavLink className='menu-link' to='/district'>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Earth.svg')} />
              </span>
              <span className='menu-text'>
                <FormattedMessage id='main.module.district' />
              </span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin: Consumer :1 Level*/}
        {authToken?.find((item) => item.role === Constants.ACCESS_HOME_MODULE_ROLE) && (
          <li
            className={`menu-item ${getMenuItemActive('/consumer', true)}`}
            aria-haspopup='true'
            data-menu-toggle='hover'
          >
            <NavLink className='menu-link' to='/consumer'>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/General/User.svg')} />
              </span>
              <span className='menu-text'>
                <FormattedMessage id='main.module.consumer' />
              </span>
            </NavLink>
            <div className='menu-submenu'>
              <i className='menu-arrow' />
              <ul className='menu-subnav'>
                <li className='menu-item menu-item-parent' aria-haspopup='true'>
                  <span className='menu-link'>
                    <span className='menu-text'>
                      <FormattedMessage id='main.module.consumer' />
                    </span>
                  </span>
                </li>
                {/* begin::2 Level */}
                <li className={`menu-item ${getMenuItemActive('/consumer/kid/list')}`} aria-haspopup='true'>
                  <NavLink className='menu-link' to='/consumer/kid/list'>
                    <i className='menu-bullet menu-bullet-dot'>
                      <span />
                    </i>
                    <span className='menu-text'>
                      <FormattedMessage id='general.child' />
                    </span>
                  </NavLink>
                </li>
                {/* end::2 Level */}
                {/* begin::2 Level */}
                <li className={`menu-item ${getMenuItemActive('/consumer/parent/list')}`} aria-haspopup='true'>
                  <NavLink className='menu-link' to='/consumer/parent/list'>
                    <i className='menu-bullet menu-bullet-dot'>
                      <span />
                    </i>
                    <span className='menu-text'>
                      <FormattedMessage id='general.parent' />
                    </span>
                  </NavLink>
                </li>
                {/* end::2 Level */}
              </ul>
            </div>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin: School :1 Level*/}
        {authToken?.find((item) => item.role === Constants.ACCESS_SCHOOL_MODULE_ROLE) && (
          <li className={`menu-item ${getMenuItemActive('/school', false)}`} aria-haspopup='true'>
            <NavLink className='menu-link' to='/school'>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Building.svg')} />
              </span>
              <span className='menu-text'>
                <FormattedMessage id='main.module.school' />
              </span>
            </NavLink>
            <div className='menu-submenu'>
              <i className='menu-arrow' />
              <ul className='menu-subnav'>
                {/* begin: 2 Level */}
                {authToken?.find((item) => item.role === Constants.EXPIRATION_DATE_ROLE) && (
                  <li className={`menu-item ${getMenuItemActive('/school/edit-expiration')}`} aria-haspopup='true'>
                    <NavLink className='menu-link' to='/school/edit-expiration'>
                      <i className='menu-bullet menu-bullet-dot'>
                        <span />
                      </i>
                      <span className='menu-text'>
                        <FormattedMessage id='school.module.edit.expiration.time' />
                      </span>
                    </NavLink>
                  </li>
                )}
                {authToken?.find((item) => item.role === Constants.SET_MATH_WORLD_ROLE) && (
                  <li className={`menu-item ${getMenuItemActive('/school/practice-math')}`} aria-haspopup='true'>
                    <NavLink className='menu-link' to='/school/practice-math'>
                      <i className='menu-bullet menu-bullet-dot'>
                        <span />
                      </i>
                      <span className='menu-text'>
                        <FormattedMessage id='school.module.set.math.world' />
                      </span>
                    </NavLink>
                  </li>
                )}
                {authToken?.find((item) => item.role === Constants.SET_ENGLISH_WORLD_ROLE) && (
                  <li className={`menu-item ${getMenuItemActive('/school/practice-english')}`} aria-haspopup='true'>
                    <NavLink className='menu-link' to='/school/practice-english'>
                      <i className='menu-bullet menu-bullet-dot'>
                        <span />
                      </i>
                      <span className='menu-text'>
                        <FormattedMessage id='school.module.set.english.world' />
                      </span>
                    </NavLink>
                  </li>
                )}
                {/* end: 2 Level */}
              </ul>
            </div>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin: Teacher :1 Level*/}
        {authToken?.find((item) => item.role === Constants.ACCESS_TEACHER_MODULE_ROLE) && (
          <li className={`menu-item ${getMenuItemActive('/teacher', false)}`} aria-haspopup='true'>
            <NavLink className='menu-link' to='/teacher'>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/General/User.svg')} />
              </span>
              <span className='menu-text'>
                <FormattedMessage id='main.module.teacher' />
              </span>
            </NavLink>
            <div className='menu-submenu'>
              <i className='menu-arrow' />
              <ul className='menu-subnav'>
                {/* begin: 2 Level */}
                {authToken?.find((item) => item.role === Constants.COPY_ASSIGNMENT_ROLE) && (
                  <>
                    <li className={`menu-item ${getMenuItemActive('/teacher/copy-assignment')}`}>
                      <NavLink className='menu-link' to='/teacher/copy-assignment'>
                        <i className='menu-bullet menu-bullet-dot'>
                          <span />
                        </i>
                        <span className='menu-text'>
                          <FormattedMessage id='teacher.module.copy.assignment' />
                        </span>
                      </NavLink>
                    </li>
                    <li className={`menu-item ${getMenuItemActive('/teacher/topic-test-assignment')}`}>
                      <NavLink className='menu-link' to='/teacher/topic-test-assignment'>
                        <i className='menu-bullet menu-bullet-dot'>
                          <span />
                        </i>
                        <span className='menu-text'>
                          <FormattedMessage id='teacher.module.topic.test.assignment' />
                        </span>
                      </NavLink>
                    </li>
                    <li className={`menu-item ${getMenuItemActive('/teacher/manage-assignment')}`}>
                      <NavLink className='menu-link' to='/teacher/manage-assignment'>
                        <i className='menu-bullet menu-bullet-dot'>
                          <span />
                        </i>
                        <span className='menu-text'>
                          <FormattedMessage id='teacher.module.manage.assignment' />
                        </span>
                      </NavLink>
                    </li>
                  </>
                )}
                {/* end: 2 Level */}
              </ul>
            </div>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin: Class :1 Level*/}
        {authToken?.find((item) => item.role === Constants.ACCESS_CLASS_MODULE_ROLE) && (
          <li className={`menu-item ${getMenuItemActive('/class', false)}`} aria-haspopup='true'>
            <NavLink className='menu-link' to='/class'>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Group.svg')} />
              </span>
              <span className='menu-text'>
                <FormattedMessage id='main.module.class' />
              </span>
            </NavLink>
            <div className='menu-submenu'>
              <i className='menu-arrow'></i>
              <ul className='menu-subnav'>
                {authToken?.find((item) => item.role === Constants.ASSIGN_CLASS_ROLE) && (
                  <li className={`menu-item ${getMenuItemActive('/class/student-assignment')}`}>
                    <NavLink className='menu-link' to='/class/student-assignment'>
                      <i className='menu-bullet menu-bullet-dot'>
                        <span />
                      </i>
                      <span className='menu-text'>
                        <FormattedMessage id='class.module.assignment.class' />
                      </span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin: Student :1 Level*/}
        {authToken?.find((item) => item.role === Constants.ACCESS_STUDENT_MODULE_ROLE) && (
          <li className={`menu-item ${getMenuItemActive('/student', false)}`} aria-haspopup='true'>
            <NavLink className='menu-link' to='/student'>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Contact1.svg')} />
              </span>
              <span className='menu-text'>
                <FormattedMessage id='main.module.student' />
              </span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin: Report :1 Level*/}
        {authToken?.find((item) => item.role === 'Constants.RUN_REPORT_ROLE') && (
          <li className={`menu-item ${getMenuItemActive('/report', false)}`} aria-haspopup='true'>
            <NavLink className='menu-link' to='/report'>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')} />
              </span>
              <span className='menu-text'>
                <FormattedMessage id='main.module.report' />
              </span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin: QB :1 Level*/}
        {authToken?.find((item) => item.role === 'Constants.ADMIN_QB_ROLE') && (
          <li className={`menu-item ${getMenuItemActive('/qb', false)}`} aria-haspopup='true'>
            <NavLink className='menu-link' to='/qb'>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Book-open.svg')} />
              </span>
              <span className='menu-text'>
                <FormattedMessage id='main.module.qb' />
              </span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin: Curriculum :1 Level*/}
        {authToken?.find((item) => item.role === 'Constants.ACCESS_CURRICULUM_MODULE_ROLE') && (
          <li className={`menu-item ${getMenuItemActive('/curriculum', false)}`} aria-haspopup='true'>
            <NavLink className='menu-link' to='/curriculum'>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Book.svg')} />
              </span>
              <span className='menu-text'>
                <FormattedMessage id='main.module.curriculum' />
              </span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin: Assignment :1 Level*/}
        {authToken?.find((item) => item.role === 'Constants.ACCESS_ASSIGNMENTBUCKET_ROLE') && (
          <li className={`menu-item ${getMenuItemActive('/assignment', false)}`} aria-haspopup='true'>
            <NavLink className='menu-link' to='/assignment'>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Clipboard-list.svg')} />
              </span>
              <span className='menu-text'>
                <FormattedMessage id='assignment.bucket.module.manage' />
              </span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin: Accounting :1 Level*/}
        {authToken?.find((item) => item.role === 'Constants.ACCESS_TRANSACTION_MODULE_ROLE') && (
          <li className={`menu-item ${getMenuItemActive('/accounting', false)}`} aria-haspopup='true'>
            <NavLink className='menu-link' to='/accounting'>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Calculator.svg')} />
              </span>
              <span className='menu-text'>
                <FormattedMessage id='main.module.accounting' />
              </span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin: Prac Plus/ Classroom :1 Level*/}
        {authToken?.find((item) => item.role === 'Constants.ACCESS_CLASSROOM_MODULE_ROLE') && (
          <li className={`menu-item ${getMenuItemActive('/prac-plus', false)}`} aria-haspopup='true'>
            <NavLink className='menu-link' to='/prac-plus'>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Group.svg')} />
              </span>
              <span className='menu-text'>
                <FormattedMessage id='main.module.classroom' />
              </span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin: Practice/ Library :1 Level*/}
        {authToken?.find((item) => item.role === 'Constants.ACCESS_LIBRARY_MODULE_ROLE') && (
          <li className={`menu-item ${getMenuItemActive('/practice', false)}`} aria-haspopup='true'>
            <NavLink className='menu-link' to='/practice'>
              <span className='svg-icon menu-icon'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Library.svg')} />
              </span>
              <span className='menu-text'>
                <FormattedMessage id='main.module.library' />
              </span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
