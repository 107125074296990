import React, { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LayoutSplashScreen } from '../../../../layout';
import { actionTypes } from '../redux/authRedux';

const Logout = () => {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth, shallowEqual);

  useEffect(() => {
    if (authState.authToken) dispatch({ type: actionTypes.LOGOUT });
  }, [authState.authToken, dispatch]);

  return authState.authToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
};

export default Logout;
