import { call, put, takeLatest } from 'redux-saga/effects';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as Api from './authCrud';
import { createFormData, Constants } from '../../../../helpers';
import XMLParser from 'react-xml-parser';

export const actionTypes = {
  AUTH_ERROR: '[UnAuth] Auth API',
  USER_LOADED: '[Authed] Auth API',
  LOGIN: '[Login] Action',
  LOGOUT: '[Logout] Action',
  SESSION_INVALID: Constants.SESSION_INVALID
};

const initialState = {
  actionsLoading: false,
  authToken: undefined,
  lastError: undefined,
  sessionInvalid: undefined,
  user: undefined,
  userId: undefined,
  userType: undefined
};

export const reducer = persistReducer(
  {
    key: Constants.STORAGE_KEY, // will remove on logout action
    storage: storage,
    whitelist: ['authToken', 'user', 'userId', 'userType', 'sessionInvalid']
  },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.LOGIN: {
        return { ...state, ...initialState, user: action.payload.username, actionsLoading: true };
      }

      case actionTypes.AUTH_ERROR: {
        const { lastError } = action;
        return { ...state, lastError, authToken: undefined, actionsLoading: false };
      }

      case actionTypes.USER_LOADED: {
        const { authToken, userId, userType } = action;
        return { ...state, lastError: undefined, authToken, userId, userType };
      }

      case actionTypes.SESSION_INVALID: {
        return { ...state, sessionInvalid: true };
      }

      case actionTypes.LOGOUT: {
        return { ...state, ...initialState };
      }

      default:
        return state;
    }
  }
);

export function* saga() {
  yield takeLatest(actionTypes.LOGIN, function* loginSaga(action) {
    try {
      const formData = createFormData(action.payload, 'loginInfo');
      const response = yield call(Api.login, formData);

      const list = new XMLParser().parseFromString(response.data).children;
      const status = list?.find((item) => item.name === Constants.STATUS);
      if (status.value === Constants.STATUS_SUCCESS) {
        const userId = list?.find((item) => item.name === 'user_id')?.value;
        const userType = list?.find((item) => item.name === 'user_type')?.value;
        const roleList = list?.find((item) => item.name === 'roles');
        const roles = roleList?.children?.map((item) => ({ role: item.value }));
        yield put({ type: actionTypes.USER_LOADED, userId, userType, authToken: roles || [] });
      } else {
        yield put({ type: actionTypes.AUTH_ERROR, lastError: 'u/pw' });
      }
    } catch (e) {
      yield put({ type: actionTypes.AUTH_ERROR, lastError: Constants.SERVER_ERROR });
    }
  });
}
