import { createSlice } from '@reduxjs/toolkit';

export const callTypes = {
  list: 'list',
  action: 'action'
};

const initialUtilityState = {
  listLoading: false,
  actionsLoading: false,
  lastError: undefined,
  userForLookup: undefined
};

export const utilitySlice = createSlice({
  name: 'utility',
  initialState: initialUtilityState,
  reducers: {
    startCall: (state, action) => {
      const { callType } = action.payload;
      state.lastError = null;
      if (callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    catchError: (state, action) => {
      state.lastError = action.payload.lastError;
      state.actionsLoading = false;
    },
    userInfoFetched: (state, action) => {
      state.lastError = null;
      state.actionsLoading = false;
      state.userForLookup = action.payload.userForLookup;
    }
  }
});
