import React from 'react';

export const Radio = ({ checkBeforeText = false, size = 'lg', className = '', children, ...props }) => {
  return (
    <label
      className={`radio ${size === 'lg' ? 'radio-lg' : ''} ${props.disabled ? 'radio-disabled' : ''} ${className}`}
    >
      <input type="radio" {...props} />
      {checkBeforeText && (
        <>
          <span className="mr-2" /> {children}
        </>
      )}
      {!checkBeforeText && (
        <>
          {children} <span className="ml-2" />
        </>
      )}
    </label>
  );
};
