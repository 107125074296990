import { createSlice } from '@reduxjs/toolkit';
import { Constants } from '../../../../../helpers';

export const initialStudentsAssignment = {
  schoolId: '',
  classId: '',
  sort: 'firstname',
  grade: 0,
  extraSort: 'firstname',
  confirmAssign: false
};

export const initialClass = {
  classId: '',
  schoolId: '',
  teacherId: '',
  grade: '',
  className: '',
  type: '1', //1:main, 0:supplemental
  templateId: '',
  templateDisable: false
};

export const initialFilter = {
  filter: {
    schoolId: '',
    teacherId: '',
    grade: '',
    className: ''
  },
  sortOrder: 'desc',
  sortField: 'id',
  pageNumber: 1,
  pageSize: 10
};

const initialClassState = {
  lastInfo: undefined,
  lastError: null,
  listLoading: null,
  actionsLoading: null,
  startSort: undefined,
  totalCount: 0,
  entities: undefined,
  sourceEntities: undefined,
  currentClass: undefined,
  currentAction: undefined,
  classForEdit: undefined,
  classTopicForEdit: undefined,
  dirty: undefined,
  classForExcel: undefined,
  studentsAssignment: { ...initialStudentsAssignment },
  studentsAssignmentForExcel: undefined,
  listFilter: initialFilter
};

export const callTypes = {
  list: 'list',
  action: 'action'
};

export const classesSlice = createSlice({
  name: 'classes',
  initialState: initialClassState,
  reducers: {
    reinitialize: (state, action) => ({ ...initialClassState }),
    storeFormStatus: (state, action) => {
      state.dirty = action.payload.dirty;
    },
    setupSortTable: (state, action) => {
      state.startSort = action.payload.startSort;
    },
    startCall: (state, action) => {
      state.lastError = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    catchError: (state, action) => {
      const { lastError, callType } = action.payload;
      state.lastError = lastError;
      if (callType === callTypes.list) {
        state.listLoading = false;
        if (lastError.errorKeys && lastError.errorKeys.find((e) => e === Constants.PERMISSION_DENIED)) {
          state.entities = null;
          state.sourceEntities = null;
          state.totalCount = 0;
        }
      } else {
        state.actionsLoading = false;
      }
    },
    classTableSorted: (state, action) => {
      const { entities, listFilter } = action.payload;
      state.lastInfo = null;
      state.entities = entities;
      state.listFilter = listFilter;
    },
    classesFetched: (state, action) => {
      const { entities, sourceEntities, totalCount, listFilter } = action.payload;
      state.lastError = null;
      state.listLoading = false;
      state.totalCount = totalCount;
      state.entities = entities;
      state.sourceEntities = sourceEntities;
      state.listFilter = listFilter;
      if (!state.lastInfo) {
        state.dirty = false;
        state.currentAction = null;
        state.currentClass = null;
        state.classForExcel = null;
        state.studentsAssignmentForExcel = null;
      } else {
        state.lastInfo = null;
      }
    },
    classDeleted: (state, action) => {
      const { classId, currentAction } = action.payload;
      state.lastInfo = true;
      state.lastError = null;
      state.actionsLoading = false;
      state.currentAction = currentAction;
      state.currentClass = state.entities.find((el) => el.classId === classId)?.className;
      state.entities = state.entities.filter((el) => el.classId !== classId);
      state.sourceEntities = state.sourceEntities.filter((el) => el.classId !== classId);
    },
    classFetched: (state, action) => {
      state.lastError = null;
      state.lastInfo = null;
      state.actionsLoading = action.payload.loading;
      state.classForEdit = action.payload.classForEdit;
    },
    classTopicFetched: (state, action) => {
      state.lastError = null;
      state.lastInfo = null;
      state.actionsLoading = false;
      state.classTopicForEdit = action.payload.classTopicForEdit;
    },
    classTopicDeleted: (state, action) => {
      state.lastError = null;
      state.lastInfo = null;
      state.actionsLoading = false;
      state.classTopicForEdit = {
        ...state.classTopicForEdit,
        topicId: '',
        topics: state.classTopicForEdit.topics.filter((el) => action.payload.topicId !== el.bucketName),
        students: []
      };
    },
    classTopicStudentsDeleted: (state, action) => {
      state.lastError = null;
      state.lastInfo = null;
      state.actionsLoading = false;
      state.classTopicForEdit = {
        ...state.classTopicForEdit,
        students: state.classTopicForEdit.students.filter(
          (el) => !action.payload.students.split(',').includes(el.userId)
        )
      };
    },
    classSaved: (state, action) => {
      const { currentAction, currentClass } = action.payload;
      state.lastError = null;
      state.lastInfo = true;
      state.actionsLoading = false;
      state.currentAction = currentAction;
      state.currentClass = currentClass;
      state.classForEdit = null;
      state.classTopicForEdit = null;
    },
    classExcelUploaded: (state, action) => {
      const { lastError, dirty, classForExcel } = action.payload;
      state.lastInfo = true;
      state.lastError = lastError;
      state.actionsLoading = false;
      state.dirty = dirty;
      state.classForExcel = classForExcel;
    },
    studentsAssignmentFetched: (state, action) => {
      const { loading, studentsAssignment } = action.payload;
      state.lastInfo = null;
      state.lastError = null;
      state.actionsLoading = loading;
      state.studentsAssignment = studentsAssignment;
    },
    studentsAssignmentExcelUploaded: (state, action) => {
      const { lastError, dirty, studentsAssignmentForExcel } = action.payload;
      state.lastInfo = true;
      state.lastError = lastError;
      state.actionsLoading = false;
      state.dirty = dirty;
      state.studentsAssignmentForExcel = studentsAssignmentForExcel;
    }
  }
});
