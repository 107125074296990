import React from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { actionTypes } from '../redux/authRedux';
import { Constants } from '../../../../helpers';
import { ActionButton } from '../../../../partials/controls';

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const Login = () => {
  const dispatch = useDispatch();
  const currentState = useSelector((state) => state.auth, shallowEqual);

  const intl = useIntl();
  const usernameMsg = intl.formatMessage({ id: 'general.username' });
  const passwordMsg = intl.formatMessage({ id: 'general.password' });
  const requiredString = Yup.string().required(intl.formatMessage({ id: 'auth.validation.required_field' }));

  const LoginSchema = Yup.object().shape({
    username: requiredString,
    password: requiredString
  });

  const getInputClasses = (field) => {
    if (formik.touched[field] && formik.errors[field]) return 'is-invalid';
    if (formik.touched[field] && !formik.errors[field]) return 'is-valid';
    return '';
  };

  const formik = useFormik({
    initialValues: { username: '', password: '' },
    validationSchema: LoginSchema,
    onSubmit: (values, {}) => {
      dispatch({ type: actionTypes.LOGIN, payload: values });
    }
  });

  return (
    <div className='login-form login-signin' id='kt_login_signin_form'>
      {/* begin::Head */}
      <div className='text-center mb-10 mb-lg-20'>
        <h3 className='font-size-h1'>
          <FormattedMessage id='auth.login.title' />
        </h3>
        <p className='text-muted font-weight-bold'>
          <FormattedMessage id='auth.login.desc' />
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form onSubmit={formik.handleSubmit} className='form fv-plugins-bootstrap fv-plugins-framework'>
        {currentState.lastError && (
          <div className='mb-10 alert alert-custom alert-light-danger alert-dismissible'>
            <div className='alert-text font-weight-bold'>
              {currentState.lastError === Constants.SERVER_ERROR ? (
                <FormattedMessage id={Constants.SERVER_ERROR} />
              ) : (
                <FormattedMessage id='auth.validation.invalid_login' />
              )}
            </div>
          </div>
        )}

        <div className='form-group fv-plugins-icon-container'>
          <input
            placeholder={usernameMsg}
            type='text'
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('username')}`}
            name='username'
            {...formik.getFieldProps('username')}
          />
          {formik.touched.username && formik.errors.username ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.username}</div>
            </div>
          ) : null}
        </div>
        <div className='form-group fv-plugins-icon-container'>
          <input
            placeholder={passwordMsg}
            type='password'
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('password')}`}
            name='password'
            {...formik.getFieldProps('password')}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className='form-group d-flex flex-wrap justify-content-between align-items-center'>
          <Link to='/auth/forgot-password' className='text-dark-50 text-hover-primary my-3 mr-2' id='kt_login_forgot'>
            <FormattedMessage id='auth.forgot.button' />
          </Link>
          <ActionButton
            type='submit'
            loading={currentState.actionsLoading}
            className='btn-primary font-weight-bold px-9 py-4 my-3'
          >
            <FormattedMessage id='login.form.login.button' />
          </ActionButton>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
};

export default Login;
