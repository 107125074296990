import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ErrorPage1 } from './ErrorPage1';
import { ErrorPage } from './ErrorPage';

export default function ErrorsRoutes() {
  return (
    <Switch>
      <Redirect from="/error" exact={true} to="/error/404" />
      <Route path="/error/404" component={ErrorPage} />
      <Route path="/error/error-v1" component={ErrorPage1} />
    </Switch>
  );
}
