import React, { useMemo } from 'react';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../core/Layout';
import { LanguageSelectorDropdown } from '../extras/dropdowns/LanguageSelectorDropdown';
import { QuickUserToggler } from '../extras/QuiclUserToggler';

export function Topbar() {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      viewLanguagesDisplay: objectPath.get(uiService.config, 'extras.languages.display'),
      viewUserDisplay: objectPath.get(uiService.config, 'extras.user.display')
    };
  }, [uiService]);

  return (
    <div className='topbar'>
      {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />}

      {layoutProps.viewUserDisplay && <QuickUserToggler />}
    </div>
  );
}
