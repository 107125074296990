import React from 'react';
import { useField, useFormikContext } from 'formik';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { Constants } from '../../../helpers';

export const MaterialDatePicker = ({ skipTouched, className = '', ...props }) => {
  const { formatMessage } = useIntl();
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  let date;
  if ((skipTouched && meta.error) || (!skipTouched && meta.touched && meta.error)) {
    if (field.value === null) {
      date = '';
    } else {
      date = field.value;
    }
  } else if (field.value) {
    date = field.value;
  } else {
    date = null;
  }

  const format = props.format === undefined ? Constants.UI_DATE_FORMAT : props.format;

  const minDate = props.minDate ? props.minDate : moment(Constants.DEFAULT_MIN_DATE);
  const maxDate = props.maxDate;

  const fullWidth = props.fullWidth === undefined ? true : props.fullWidth;

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          {...field}
          {...props}
          clearable
          autoOk
          format={format}
          placeholder={format}
          onChange={(value) => setFieldValue(field.name, value === null ? '' : value)}
          value={date}
          className={fullWidth ? `w-100 ${className}` : className}
          InputAdornmentProps={{ position: 'start' }}
          invalidDateMessage={meta.error ? '' : formatMessage({ id: 'general.invalid.date' })}
          minDate={minDate}
          maxDate={maxDate}
          minDateMessage={
            minDate && !props.disabled
              ? formatMessage({ id: 'general.date.available.after' }, { min: moment(minDate).format(format) })
              : ''
          }
          maxDateMessage={
            maxDate && !props.disabled
              ? formatMessage({ id: 'general.date.available.before' }, { max: moment(maxDate).format(format) })
              : ''
          }
        />
      </MuiPickersUtilsProvider>
      {skipTouched && meta.error && <div style={{ color: Constants.ERROR_COLOR }}>{meta.error}</div>}
      {meta.touched && meta.error && <div style={{ color: Constants.ERROR_COLOR }}>{meta.error}</div>}
    </>
  );
};
