import { createSlice } from '@reduxjs/toolkit';
import { Constants } from '../../../../../helpers';

export const initialCopyAssignment = {
  schoolId: '',
  teacherId: '',
  classId: '',
  studentId: '',
  targetClassId: '',
  sort: 'student_id',
  targetStudents: [],
  selectedStudents: []
};

export const initialTeacher = {
  userId: '',
  username: '',
  password: '',
  customTeacherId: '',
  firstname: '',
  lastname: '',
  grade: '0',
  email: '',
  phone: '',
  languageId: 1,
  state: '',
  siteName: '',
  expiryDate: '',
  active: 1,
  schoolId: ''
};

export const initialFilter = {
  filter: {
    schoolId: '',
    customTeacherId: '',
    username: '',
    firstname: '',
    lastname: '',
    email: '',
    grade: ''
  },
  sortOrder: 'desc', // asc || desc
  sortField: 'id',
  pageNumber: 1,
  pageSize: 10
};

const initialTeachersState = {
  totalCount: 0,
  startSort: undefined,
  entities: undefined,
  sourceEntities: undefined,
  lastInfo: undefined,
  lastError: undefined,
  listLoading: false,
  actionsLoading: false,
  currentAction: undefined,
  currentTeachers: undefined,
  dirty: undefined,
  teachersForExcel: undefined,
  topicTestAssignmentsForExcel: undefined,
  copyAssignmentData: { ...initialCopyAssignment },
  listFilter: initialFilter
};

export const callTypes = {
  list: 'list',
  action: 'action'
};

export const teachersSlice = createSlice({
  name: 'teachers',
  initialState: initialTeachersState,
  reducers: {
    reinitialize: (state, action) => ({ ...initialTeachersState }),
    storeFormStatus: (state, action) => {
      state.dirty = action.payload.dirty;
    },
    startCall: (state, action) => {
      state.lastError = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    // sort table
    setupSortTable: (state, action) => {
      state.startSort = action.payload.startSort;
    },
    //catch error
    catchError: (state, action) => {
      const { lastError, callType } = action.payload;
      state.lastError = lastError;
      if (callType === callTypes.list) {
        state.listLoading = false;
        if (lastError.errorKeys && lastError.errorKeys.find((e) => e === Constants.PERMISSION_DENIED)) {
          state.entities = null;
          state.sourceEntities = null;
          state.totalCount = 0;
        }
      } else {
        state.actionsLoading = false;
      }
    },
    teachersTableSorted: (state, action) => {
      const { entities, listFilter } = action.payload;
      state.entities = entities;
      state.listFilter = listFilter;
    },
    // fetch teachers
    teachersFetched: (state, action) => {
      const { totalCount, entities, sourceEntities, listFilter } = action.payload;
      state.lastError = null;
      state.listLoading = false;
      state.totalCount = totalCount;
      state.entities = entities;
      state.sourceEntities = sourceEntities;
      state.listFilter = listFilter;
      if (!state.lastInfo) {
        state.dirty = false;
        state.currentAction = null;
        state.currentTeachers = null;
        state.teachersForExcel = null;
        state.topicTestAssignmentsForExcel = null;
      } else {
        state.lastInfo = null;
      }
    },
    teacherDeleted: (state, action) => {
      const { currentAction, userId } = action.payload;
      state.lastInfo = true;
      state.lastError = null;
      state.actionsLoading = false;
      state.currentAction = currentAction;
      state.currentTeachers = state.entities.find((el) => el.userId === userId)?.customTeacherId;
      state.entities = state.entities.filter((el) => el.userId !== userId);
      state.sourceEntities = state.sourceEntities.filter((el) => el.userId !== userId);
    },
    // get a teacher
    teacherFetched: (state, action) => {
      state.lastError = null;
      state.actionsLoading = false;
      state.teacherForEdit = action.payload.teacherForEdit;
    },
    // save teacher info
    teacherSaved: (state, action) => {
      const { currentAction, currentTeachers } = action.payload;
      state.lastInfo = true;
      state.lastError = null;
      state.actionsLoading = false;
      state.currentAction = currentAction;
      state.currentTeachers = currentTeachers;
      state.teacherForEdit = null;
    },
    teachersExcelUploaded: (state, action) => {
      const { lastError, dirty, teachersForExcel } = action.payload;
      state.lastInfo = true;
      state.lastError = lastError;
      state.actionsLoading = false;
      state.dirty = dirty;
      state.teachersForExcel = teachersForExcel;
    },
    // topic test assignments
    topicTestAssignmentsExcelUploaded: (state, action) => {
      const { lastError, dirty, topicTestAssignmentsForExcel } = action.payload;
      state.lastInfo = true;
      state.lastError = lastError;
      state.actionsLoading = false;
      state.dirty = dirty;
      state.topicTestAssignmentsForExcel = topicTestAssignmentsForExcel;
    },
    // fetched target students for copy assignment
    prepareCopyAssignmentFetched: (state, action) => {
      state.lastError = null;
      state.actionsLoading = false;
      state.copyAssignmentData = { ...initialCopyAssignment, ...action.payload.data };
    },
    // fetched target students for copy assignment
    copyAssignmentFetched: (state, action) => {
      state.lastError = null;
      state.actionsLoading = false;
      state.copyAssignmentData = { ...state.copyAssignmentData, ...action.payload.data };
    }
  }
});
