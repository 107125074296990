/* eslint-disable */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { toAbsoluteUrl } from '../../helpers';

export function TilesWidget({ className, widgetHeight = '175px' }) {
  const backgroundImageUrl = toAbsoluteUrl('/media/svg/patterns/taieri.svg');

  return (
    <div
      className={`card card-custom bgi-no-repeat ${className}`}
      style={{
        height: widgetHeight,
        backgroundColor: '#663259',
        backgroundPosition: 'calc(100% + 0.5rem) 100%',
        backgroundSize: '100% auto',
        backgroundImage: `url("${backgroundImageUrl}")`
      }}
    >
      {/* begin::Body */}
      <div className='card-body d-flex align-items-center'>
        <div className='flex-column-auto my-5 pb-lg-0 pb-10'>
          <img alt='Logo' className='max-h-40px' src={toAbsoluteUrl('/media/logos/logo-itelij.png')} />
        </div>
      </div>
      {/* end::Body */}
    </div>
  );
}
