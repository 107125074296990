import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHtmlClassService } from '../../core/Layout';

export function Footer() {
  const year = new Date().getFullYear();
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses('footer', true),
      footerContainerClasses: uiService.getClasses('footer_container', true)
    };
  }, [uiService]);

  return (
    <div className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`} id='kt_footer'>
      <div
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className='text-dark order-2 order-md-1'>
          <span className='text-muted font-weight-bold mr-2'>{year.toString()} &copy; PLANETii</span>
        </div>
        <div className='nav nav-dark order-1 order-md-2'>
          <a href='https://www.planetii.com' target='_blank' rel='noopener noreferrer' className='nav-link pr-3 pl-0'>
            <FormattedMessage id='general.about' />
          </a>
        </div>
      </div>
    </div>
  );
}
