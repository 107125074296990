import { createSlice } from '@reduxjs/toolkit';

export const initialCreateConsumer = {
  parentInfo: {
    username: '',
    password: '',
    firstname: '',
    lastname: '',
    email: '',
    phoneMain: '',
    addr1: '',
    city: '',
    gender: 'M',
    languageId: '2'
  },
  kidInfo: {
    username: '',
    password: '',
    firstname: '',
    lastname: '',
    gender: 'M',
    piiLevel: '1',
    languageId: '2',
    schoolId: '',
    countryId: '2'
  },
  transactionInfo: {
    // input
    nameoncheck: '',
    cheque: '',
    amount: 600,
    drn: '', // deposit slip
    // date picker
    chequedate: '',
    depositdate: '',
    // select
    subscription: 12,
    categoryId: '4', //consumer
    paymentId: 1,
    paymentName: 'PPS',
    bankId: '',
    salesmanId: ''
  }
};

export const callTypes = {
  list: 'list',
  action: 'action'
};

const initialConsumerState = {
  lastInfo: undefined,
  lastError: undefined,
  listLoading: false,
  actionsLoading: false,
  dirty: false,
  consumerForExcel: undefined
};

export const consumerSlice = createSlice({
  name: 'consumer',
  initialState: initialConsumerState,
  reducers: {
    reinitialize: (state, action) => ({ ...initialConsumerState }),
    storeFormStatus: (state, action) => {
      state.dirty = action.payload.dirty;
    },
    startCall: (state, action) => {
      state.lastError = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    catchError: (state, action) => {
      const { lastError, callType } = action.payload;
      state.lastError = lastError;
      if (callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    consumersExcelUploaded: (state, action) => {
      const { lastError, dirty, consumerForExcel } = action.payload;
      state.lastError = lastError;
      state.dirty = dirty;
      state.consumerForExcel = consumerForExcel;
      state.actionsLoading = false;
    }
  }
});
