import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { Constants } from '../../../helpers';

const useStyles = makeStyles((theme) => ({
  bottomSpace: {
    marginBottom: theme.spacing(1)
  }
}));

export const MaterialSelect = ({
  name,
  label,
  value,
  disabled = false,
  errors,
  fullWidth = true,
  onChange,
  list = [],
  valueKey = 'id',
  nameKey = 'name',
  className = '',
  ...rest
}) => {
  const styles = useStyles();
  return (
    <>
      {label && (
        <InputLabel htmlFor={name} className={disabled ? 'Mui-disabled' : ''}>
          <FormattedMessage id={label} /> {rest.required && '*'}
        </InputLabel>
      )}
      <Select
        fullWidth={fullWidth}
        value={value}
        disabled={disabled}
        onChange={onChange}
        error={!!errors}
        inputProps={{
          id: name,
          name
        }}
        className={errors ? className : clsx(styles.bottomSpace, className)}
        {...rest}
      >
        {list?.map((el, index) => (
          <MenuItem key={index} value={el[valueKey]}>
            {el[nameKey]}
          </MenuItem>
        ))}
      </Select>
      {errors && (
        <div className={styles.bottomSpace} style={{ color: Constants.ERROR_COLOR }}>
          {errors}
        </div>
      )}
    </>
  );
};
