import React from 'react';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Constants } from '../../../helpers';

const useStyles = makeStyles((theme) => ({
  bottomSpace: {
    marginBottom: theme.spacing(1.5)
  }
}));

export const MaterialTextField = ({
  name,
  value,
  onChange = () => {},
  label,
  type = 'text',
  errors = '',
  disabled = false,
  fullWidth = true,
  variant = 'outlined',
  size = 'small',
  regex = false,
  expression = '',
  className = '',
  maxLength = 200,
  ...rest
}) => {
  const styles = useStyles();

  return (
    <>
      <TextField
        name={name}
        value={value}
        type={type === 'pureNumber' ? 'tel' : type}
        onChange={onChange}
        disabled={disabled}
        label={label}
        fullWidth={fullWidth}
        size={size}
        inputProps={{
          maxLength
        }}
        error={!!errors}
        variant={variant}
        {...rest}
        className={errors ? className : clsx(styles.bottomSpace, className)}
        onKeyPress={(e) => {
          if (regex) {
            if (!Constants.PURE_NUMBER_ALLOW_KEYS.test(e.key) && type === 'pureNumber') e.preventDefault();
            if (!Constants.TEL_ALLOW_KEYS.test(e.key) && type === 'tel') e.preventDefault();
            if (!Constants.NUMBER_ALLOW_KEYS.test(e.key) && type === 'number') e.preventDefault();
            if (type === 'text' && expression && !expression.test(e.key)) e.preventDefault();
            if (type === 'text' && !expression && !Constants.NORMAL_PLUS_ALLOW_KEYS.test(e.key)) e.preventDefault();
          }
        }}
      />
      {errors && (
        <div className={clsx(styles.bottomSpace, className)} style={{ color: Constants.ERROR_COLOR }}>
          {errors}
        </div>
      )}
    </>
  );
};
